import React, { Component } from "react";
import { CloudTop, Rocket, Smoke, SpaceMan } from "./partials/space.js";

import RocketSVG from "../assets/images/rocket-2.svg";
import PlanetSVG from '../assets/images/planet.svg';
import CloudSVG from '../assets/images/cloud.svg';
import Cloud2SVG from '../assets/images/cloud-2.svg';
import PlanetFlagSVG from '../assets/images/planet-2.svg';
import CloudBottomSVG from '../assets/images/cloud-bottom.svg';

import strings from '../resources/strings.js';

export default class Policy extends Component {
	componentDidMount() {}
	render() {
		const {language}=this.props;
		return (
			<div className="mission">
				<img src={PlanetSVG} className="planet" />
				<img src={RocketSVG} className="rocket" />
				{/* <img src={CloudSVG} className="cloud" />
				<img src={Cloud2SVG} className="cloud-2" /> */}
				{/* <div className="empty-block-half"></div> */}
                <div style={{display: 'flex', justifyContent: 'center'}} >
                    <div style={{maxWidth: '60vw', color: 'white'}} className='terms' >
					<h2>Privacy Policy for AstroFi Social WiFi by AstroFi Tech SASU SASU - Siren 881 966 436</h2>

<h5>Last updated: 14/08/2023</h5>

<h5>AstroFi Tech SASU ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information in relation to our AstroFi Social WiFi solution. By accessing or using the AstroFi Social WiFi solution, you consent to the practices described in this Privacy Policy.</h5>

<br/>
<h4>1. Information We Collect</h4>

<h5>We may collect personal information that you provide directly to us when you use our AstroFi Social WiFi solution. This may include:</h5>

<h5>Contact Information: Name, email address, phone number, and other similar information.</h5>
<h5>WiFi Usage Data: Information about your use of the WiFi service, including data usage, duration of sessions, websites visited, and access times.</h5>
<h5>Device Information: Device type, operating system, unique device identifiers, and other technical information.</h5>
<h5>Log Data: Information collected automatically when you use our solution, such as IP address, browser type, referring/exit pages, and operating system.</h5>
<br/>
<h4>2. How We Use Your Information</h4>

<h5>We may use the information we collect for various purposes, including:</h5>

<h5>Providing and improving our AstroFi Social WiFi solution. <br/>
Communicating with you about our services, updates, and offers. <br/>
Analyzing usage patterns and trends to enhance user experience. <br/>
Customizing content and advertisements based on user preferences. <br/>
Protecting against unauthorized access, fraud, and security risks. <br/>
3. How We Disclose Your Information</h5>

<h5>We may share your personal information in the following circumstances:</h5>

<h5>
With third-party service providers who assist us in providing and improving our services<br/>
To comply with legal obligations, protect our rights, and prevent fraud or illegal activities<br/>
In connection with a business transfer, such as a merger, acquisition, or sale of assets<br/>
With your consent or at your direction<br/>
</h5>
<br/>
<h4>4. Your Choices</h4>

<h5>You have certain choices regarding your personal information:</h5>

<h5>You can access and update your personal information through your account settings<br/>
You can opt-out of receiving promotional communications from us by following the instructions provided in the communication<br/>
</h5>
<br/>
<h4>5. Data Security</h4>

<h5>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</h5>
<br/>
<h4>6. Children's Privacy</h4>

<h5>Our AstroFi Social WiFi solution is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us.</h5>

<br/>
<h4>7. Changes to this Privacy Policy</h4>

<h5>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes through the contact information you provide or by posting a prominent notice on our website.</h5>

<br/>
<h4>8. Contact Us</h4>

<h5>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at:</h5>
<br/>
<h5>AstroFi Tech SASU</h5>
<h5>101 Rue de Sèvres, 75006 Paris, France</h5>
<h5><a style={{color: 'white'}} href="mailto:privacy@astrofi.com">privacy@astrofi.com</a></h5>
<h5><a style={{color: 'white'}} href="tel:++33632457265">+33632457265</a></h5>
<br/>
<h5>By using AstroFi Social WiFi, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.</h5>

                    </div>
                </div>
				<img src={PlanetFlagSVG} className="planet-flag" />
			
				<img src={CloudBottomSVG} className="cloud-bottom" />
				<div className="empty-block-half"></div>
			</div>
		);
	}
}
