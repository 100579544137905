import React, { Component } from "react";
import YouTubeIframeLoader from "youtube-iframe";
import gsap from "gsap";

import Card from "./partials/card.js";

import Muuri from "muuri";

import CARDS from "./cards.data.js";
import bodymovin from "lottie-web";

const handleEvent = (ev, anim) => {
	anim.stop();
	anim.play();
};

class Cards extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDetails: false,
			detailsComponent: null,
			dragging: false,
		};
		const savedSortJSON = window.localStorage.getItem("boards");
		this.savedSort = [];
		if (savedSortJSON) {
			this.savedSort = JSON.parse(savedSortJSON);
		} else {
			this.savedSort = {
				WIFI: 1,
				SEO: 2,
				APP_DEV: 3,
				PROD: 4,
				WEB_DEV: 5,
				HOST: 6,
				VR: 7,
				UI: 8,
				SMS: 9,
				DM: 10,
			};
		}
	}
	componentDidMount() {
		this.grid = new Muuri(".grid", {
			dragEnabled: true,
			layout: {
				fillGaps: true,
			},
		});
		this.grid.on("dragEnd", (item, event) => {
			const items = this.grid.getItems();
			let newSort = {};
			items.map((item, index) => {
				newSort[item._element.id] = index;
			});
			window.localStorage.setItem("boards", JSON.stringify(newSort));
		});
		this.grid.on("dragStart", (item, event) => {
			this.setState({ dragging: false });
		});
		this.grid.on("dragReleaseEnd", (item) => {
			this.setState({ dragging: false });
		});
		this.grid.on("dragMove", (item) => {
			this.setState({ dragging: true });
		});
		this.forceUpdate();

		this.player = undefined;
		YouTubeIframeLoader.load((YT) => {
			this.player = new YT.Player("player", {
				height: "390",
				width: "640",
				playerVars: { autoplay: 1, controls: 0 },
				videoId: "508uoWvdR5s",
				events: {
					onReady: this._playVideo,
					onStateChange: (state) => {
						if (state.data === YT.PlayerState.ENDED) {
							this._playVideo();
						}
					},
				},
			});
		});

		CARDS.map((card) => {
			const container = document.getElementById(card.icon.props.id);
			let anim = bodymovin.loadAnimation({
				container: container,
				renderer: "svg",
				loop: false,
				autoplay: true,
				path: card.lottie,
			});
			anim.setSpeed(0.5);
			container.addEventListener(
				"mouseenter",
				(event) => handleEvent(event, anim),
				false
			);
		});

		gsap.fromTo(".item", {scale:0.8,opacity:0.5}, {
			scale: 1,
			opacity:1,
			yoyo: true,
			ease: "power1.inOut",
			delay: 0.3,
			stagger: {
				amount: 0.5,
				grid: "auto",
				from: "start",
			},
		});
	}
	_playVideo = () => {
		this.player.mute();
		this.player.playVideo();
	};
	showDetails = (detailsComponent) => {
		const { dragging } = this.state;
		if (!dragging)
			this.setState({
				detailsComponent,
				showDetails: true,
			});
	};
	hideDetails = (e) => {
		if (e.target.id === "content")
			this.setState({ showDetails: false, detailsComponent: null });
	};
	renderGrid = () => {
		const { showDetails } = this.state;

		const sortedCards = CARDS.sort(
			(a, b) => this.savedSort[a.key] - this.savedSort[b.key]
		);
		return (
			<div
				className={`${
					showDetails ? "grid-hide" : ""
				} grid mx-auto col-12`}
			>
				{sortedCards.map(
					(
						{ icon, title, size, iconStyle, detailsComponent, key },
						index
					) => (
						<Card
							grid={this.grid}
							id={key}
							key={index.toString()}
							icon={icon}
							title={title}
							size={size}
							iconStyle={iconStyle}
							onClick={() => this.showDetails(detailsComponent)}
						/>
					)
				)}
			</div>
		);
	};
	renderVideo = () => {
		return (
			<div className="video-background">
				<div className="video-foreground">
					<div id="player"></div>
				</div>
			</div>
		);
	};
	renderDetails = () => {
		const { detailsComponent, showDetails } = this.state;
		return (
			<div
				className={`${
					showDetails ? "" : "card-drag-full-hide"
				} card-drag-full shadow card-rouded`}
				onClick={this.hideDetails}
			>
				<div
					className={`item-content ${
						showDetails
							? `card-drag-full-content`
							: "card-drag-hidden"
					}`}
				>
					{detailsComponent}
				</div>
			</div>
		);
	};
	render() {
		return (
			<div className="py-3 cards">
				{this.renderGrid()}
				{this.renderDetails()}
				{this.renderVideo()}
			</div>
		);
	}
}

export default Cards;
