import React from "react";
import strings from "../resources/strings.js";

const cards = [
	require("../assets/images/Kitco Mockups.svg"),
	require("../assets/images/Jodel Mockups.svg"),
	require("../assets/images/Rehlat Mockups.svg"),
	require("../assets/images/Lord Milano Mockups.svg"),
	require("../assets/images/Inivta Mockups.svg"),
	require("../assets/images/Voucher Pay Mockups.svg"),
];

export default class Portfolio extends React.Component {
	render() {
		const { language } = this.props;
		return (
			<div className="portfolio">
				<div className="col-11 mx-auto cover-container d-flex flex-row align-items-center justify-content-between">
					<div className="mx-auto paragraph-container">
						<div className="paragraph show">
							<span className="text-light title">
								{strings[language || "french"].portefeuille}
							</span>
							<p className="col-11 text text-light p-0">
								{strings[language || "french"].portfolioPar}
							</p>
							<a href="#affaires" className="btn btn-rounded btn-grad text-light col-5 shadow font-weight-bold">
								{strings[language || "french"].Affaires}
							</a>
						</div>
					</div>
					<img
						className="cover"
						src={require("../assets/images/portfolio-1.svg")}
					/>
				</div>
				<div className="col-11 mx-auto d-flex flex-row mt-5 slides">
					{cards.map((card, index) => (
						<div className="slide">
							<img key={index} src={card} />
						</div>
					))}
				</div>
			</div>
		);
	}
}
