import React, { Component } from "react";
import { Navbar, Nav, Button, Form, FormControl } from "react-bootstrap";
import history from "../router/history.js";

class Header extends Component {
	constructor(props) {
		super(props);
	}
	renderNavMobile = () => {
		return (
			<Navbar
				className="navbar navbar-expand-lg navbar-dark d-flex d-lg-none "
				expand="lg"
			>
				<Navbar.Brand href="#" className="mx-auto">
					<img
						src={require("../assets/images/logo.svg")}
						width="64"
						alt="logo"
					/>
				</Navbar.Brand>
			</Navbar>
		);
	};
	render() {
		return (
			<div className="pt-1">
				<div className="container position-relative">
					{this.renderNavMobile()}
				</div>
			</div>
		);
	}
}

export default Header;
