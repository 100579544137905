import React from "react";
import strings from "../resources/strings.js";

const logos=[
	require('../assets/images/Coach Logo.svg'),
	require('../assets/images/Ipsos Logo.svg'),
	require('../assets/images/Jodel Logo.svg'),
	require('../assets/images/Kitco Logo.svg'),
	require('../assets/images/Invita Logo.svg'),
	require('../assets/images/Rawbot Logo.svg'),
	require('../assets/images/Rehlat Logo.svg'),
	require('../assets/images/Astrofi Logo.svg'),
	require('../assets/images/Chili\'s Logo-01.svg'),
	require('../assets/images/GoLiquid Logo.svg'),
	require('../assets/images/Schoolab Logo copy.svg'),
	require('../assets/images/Swarovski Logo.svg'),
	require('../assets/images/Lord Milano Logo.svg'),
	require('../assets/images/Voucher Pay Logo.svg'),
	require('../assets/images/Jack Daniels Logo.svg'),
]

export default class Portfolio extends React.Component {
	render() {
		const {language}=this.props;
		return(
			<div className="col-11 mx-auto">
				<p className="h1 text-light">{strings[language||'french'].Affaires.toUpperCase()}</p>
				<div className="d-flex flex-row flex-wrap align-items-center justify-content-start">
					{logos.map((logo)=><img width={120} className="m-5" src={logo}/>)}
				</div>
			</div>
		)
	}
}