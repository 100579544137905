import axios from "axios";

import config from "../config.js";

export const sendEmail = data =>
	new Promise(async (resolve, reject) => {
		try {
			await axios.post(config.backend + "/new_contact", data);
			resolve();
		} catch (err) {
			console.log(err);
			reject(err);
		}
	});

export const transitionState = (fromCB, toCB) => {
	fromCB();
	setTimeout(toCB, 300);
};