import React, { Component } from "react";
import SwipeJS from "swipejs";
import { Navbar, Nav, Button, Modal, Form, FormControl } from "react-bootstrap";

import Header from "./header.mobile.js";
import Tabber from "./tabber.mobile.js";

import CARDS from "./cards.data.mobile.js";
import bodymovin from "lottie-web";

class Cards extends Component {
	constructor(props) {
		super(props);
		this.animations = [];
		this.state = { currentCard: null, showModal: false, currentSlide: 0 };
	}
	componentDidMount() {
		this.mySwipe = new SwipeJS(document.getElementById("slider"), {
			callback: (i) => this.setState({ currentSlide: i }),
		});
		CARDS.map((card) => {
			const container = document.getElementById(card.icon.props.id);
			let anim = bodymovin.loadAnimation({
				container: container,
				renderer: "svg",
				loop: false,
				autoplay: true,
				path: card.lottie,
			});
			anim.setSpeed(0.5);
			this.animations.push(() => {
				anim.stop();
				anim.play();
			});
		});
	}
	showModal = (card) => {
		this.setState({ currentCard: card, showModal: true });
	};
	hideModal = (card) => {
		this.setState({ currentCard: null, showModal: false });
	};
	renderCards = () => {
		const cardsCom = CARDS.map((card, index) => {
			let animation = "";
			if (
				index === this.state.currentSlide &&
				this.animations.length > 0
			) {
				this.animations[index]();
			}
			return (
				<div key={index.toString()} className="card-mobile">
					<div
						className="shadow d-flex flex-column card-mobile-content bg-light mx-auto text-center align-items-center justify-content-center"
						onClick={() => this.showModal(card)}
					>
						<span>{card.icon}</span>
						<div className="h4 text-primary w-75">{card.title}</div>
					</div>
				</div>
			);
		});
		return cardsCom;
	};
	renderModal = () => {
		const { currentCard, showModal } = this.state;

		if (!currentCard) return;

		const detailsComponent = React.cloneElement(
			currentCard.detailsComponent,
			{ closeModals: this.hideModal }
		);
		return (
			<Modal show={showModal} onHide={this.hideModal} centered>
				<Modal.Body>{detailsComponent}</Modal.Body>
			</Modal>
		);
	};
	render() {
		return (
			<React.Fragment>
				<Header />
				<div id="slider" className="swipe">
					<div className="swipe-wrap">{this.renderCards()}</div>
				</div>
				<Tabber />
				{this.renderModal()}
			</React.Fragment>
		);
	}
}

export default Cards;
