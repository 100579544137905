import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from "../router/history.js";
import strings from '../resources/strings.js';

export const Twitter = ({ className }) => {
	return (
		<svg
			id="Group_71"
			data-name="Group 71"
			xmlns="http://www.w3.org/2000/svg"
			width="25.129"
			height="20.698"
			viewBox="0 0 25.129 20.698"
			className={className}
		>
			<g id="Group_70" data-name="Group 70">
				<g id="Group_65" data-name="Group 65">
					<path
						id="Path_77"
						data-name="Path 77"
						d="M297.069,885.249a10.338,10.338,0,0,1-2.539.725c.409-.068,1.012-.809,1.253-1.108a4.67,4.67,0,0,0,.846-1.54c.022-.044.038-.1-.005-.132a.145.145,0,0,0-.135.011,12.979,12.979,0,0,1-3.015,1.154.2.2,0,0,1-.208-.056,2.565,2.565,0,0,0-.264-.269,5.315,5.315,0,0,0-1.461-.895,5.088,5.088,0,0,0-2.24-.361,5.36,5.36,0,0,0-2.125.6,5.486,5.486,0,0,0-1.715,1.4,5.27,5.27,0,0,0-1.024,2.051,5.561,5.561,0,0,0-.054,2.165c.017.121.006.138-.1.121a16.1,16.1,0,0,1-10.361-5.274c-.121-.138-.187-.138-.286.011a5.254,5.254,0,0,0,.9,6.208c.2.193.412.384.637.561a5.291,5.291,0,0,1-2-.561c-.121-.076-.182-.032-.193.1a3.155,3.155,0,0,0,.033.594,5.312,5.312,0,0,0,3.274,4.235,3.04,3.04,0,0,0,.664.2,5.922,5.922,0,0,1-1.961.061c-.143-.027-.2.044-.143.181a5.517,5.517,0,0,0,4.12,3.45c.187.032.374.032.561.076-.011.017-.023.017-.033.033-.463.706-2.067,1.23-2.814,1.491a10.074,10.074,0,0,1-4.273.547c-.23-.034-.278-.032-.341,0s-.008.094.066.154c.291.193.588.363.89.528a14.111,14.111,0,0,0,2.856,1.143,15.215,15.215,0,0,0,14.77-3.454c3.026-3.008,4.087-7.155,4.087-11.307,0-.163.192-.252.306-.338a9.951,9.951,0,0,0,2-2.09.607.607,0,0,0,.124-.4v-.022C297.163,885.183,297.171,885.2,297.069,885.249Z"
						transform="translate(-272.033 -882.768)"
					/>
				</g>
				<g
					id="Group_66"
					data-name="Group 66"
					transform="translate(7.462 20.609)"
				>
					<path
						id="Path_78"
						data-name="Path 78"
						d="M281.951,910.157Z"
						transform="translate(-281.951 -910.157)"
						fill="#fff"
						fillRule="evenodd"
					/>
				</g>
				<g
					id="Group_67"
					data-name="Group 67"
					transform="translate(7.271 20.609)"
				>
					<path
						id="Path_79"
						data-name="Path 79"
						d="M281.888,910.157c-.032,0-.415,0,0,0Z"
						transform="translate(-281.696 -910.157)"
						fill="#fff"
						fillRule="evenodd"
					/>
				</g>
				<g
					id="Group_68"
					data-name="Group 68"
					transform="translate(8.309 20.613)"
				>
					<path
						id="Path_80"
						data-name="Path 80"
						d="M283.076,910.18c0-.042.445,0,0,0C283.076,910.158,283.13,910.18,283.076,910.18Z"
						transform="translate(-283.076 -910.161)"
						fill="#fff"
						fillRule="evenodd"
					/>
				</g>
				<g
					id="Group_69"
					data-name="Group 69"
					transform="translate(17.093 0.031)"
				>
					<path
						id="Path_81"
						data-name="Path 81"
						d="M294.854,882.809a.091.091,0,0,1-.1,0Z"
						transform="translate(-294.749 -882.809)"
						fill="#fff"
						fillRule="evenodd"
					/>
				</g>
			</g>
		</svg>
	);
};

export const Facebook = ({ className }) => {
	return (
		<svg
			id="Group_73"
			data-name="Group 73"
			xmlns="http://www.w3.org/2000/svg"
			width="12.007"
			height="25.845"
			viewBox="0 0 12.007 25.845"
			className={className}
		>
			<g id="Group_72" data-name="Group 72" transform="translate(0)">
				<path
					id="Path_82"
					data-name="Path 82"
					d="M335.725,892.373v12.5a.325.325,0,0,0,.326.325h4.64a.325.325,0,0,0,.326-.325v-12.7h3.364a.326.326,0,0,0,.324-.3l.324-3.826a.326.326,0,0,0-.324-.354h-3.688v-2.714a1.152,1.152,0,0,1,1.152-1.152h2.593a.326.326,0,0,0,.325-.326v-3.826a.326.326,0,0,0-.325-.326H340.38A4.655,4.655,0,0,0,335.725,884v3.689H333.4a.326.326,0,0,0-.326.326v3.826a.325.325,0,0,0,.326.325h2.32Z"
					transform="translate(-333.079 -879.348)"
					fillRule="evenodd"
				/>
			</g>
		</svg>
	);
};

export const Insta = ({ className }) => {
	return (
		<svg
			id="Group_74"
			data-name="Group 74"
			xmlns="http://www.w3.org/2000/svg"
			width="26.96"
			height="26.96"
			viewBox="0 0 26.96 26.96"
			className={className}
		>
			<path
				id="Path_83"
				data-name="Path 83"
				d="M397.412,883.871A1.585,1.585,0,1,0,399,885.456,1.585,1.585,0,0,0,397.412,883.871Zm-7.046,2.032a6.658,6.658,0,1,0,6.658,6.657A6.666,6.666,0,0,0,390.366,885.9Zm0,10.922a4.265,4.265,0,1,1,4.265-4.265A4.27,4.27,0,0,1,390.366,896.826Zm13.434-9.8a8.12,8.12,0,0,0-8.121-8.121H384.961a8.121,8.121,0,0,0-8.121,8.121v10.719a8.12,8.12,0,0,0,8.121,8.12h10.717a8.12,8.12,0,0,0,8.121-8.12Zm-2.543,10.719a5.577,5.577,0,0,1-5.577,5.577H384.961a5.577,5.577,0,0,1-5.578-5.577V887.028a5.578,5.578,0,0,1,5.578-5.577h10.717a5.577,5.577,0,0,1,5.577,5.577Z"
				transform="translate(-376.84 -878.907)"
			/>
		</svg>
	);
};

export const LinkedIn = ({ className }) => {
	return (
		<svg
			id="Group_79"
			data-name="Group 79"
			xmlns="http://www.w3.org/2000/svg"
			width="22.735"
			height="21.715"
			viewBox="0 0 22.735 21.715"
			className={className}
		>
			<g
				id="Group_77"
				data-name="Group 77"
				transform="translate(0.318 6.73)"
			>
				<g id="Group_76" data-name="Group 76">
					<g id="Group_75" data-name="Group 75">
						<path
							id="Path_84"
							data-name="Path 84"
							d="M455.885,896.474v8.037a.36.36,0,0,1-.36.36h-4.148a.36.36,0,0,1-.36-.36v-7.474c0-1.967-.7-3.31-2.465-3.31a2.663,2.663,0,0,0-2.5,1.78,3.335,3.335,0,0,0-.161,1.186v7.817a.36.36,0,0,1-.36.36h-4.147a.358.358,0,0,1-.36-.36c.009-1.99.051-11.647.008-13.917a.358.358,0,0,1,.36-.363h4.139a.359.359,0,0,1,.36.359V892.3c-.01.016-.024.032-.032.047h.032V892.3a4.833,4.833,0,0,1,4.387-2.418c3.2,0,5.6,2.092,5.6,6.588Zm-22.058,8.4h4.148a.36.36,0,0,0,.36-.36V890.589a.359.359,0,0,0-.36-.359h-4.148a.359.359,0,0,0-.359.359v13.922A.36.36,0,0,0,433.827,904.871Z"
							transform="translate(-433.468 -889.886)"
						/>
					</g>
				</g>
			</g>
			<g id="Group_78" data-name="Group 78">
				<path
					id="Path_85"
					data-name="Path 85"
					d="M438.256,883.548a2.605,2.605,0,1,1-2.605-2.606A2.606,2.606,0,0,1,438.256,883.548Z"
					transform="translate(-433.045 -880.942)"
				/>
			</g>
		</svg>
	);
};

class Footer extends Component {
	// contactUs = () => {
	// 	history.push("/contactus");
	// };
	home = () => {
		history.push("/");
	};
	render() {
		const {language}=this.props;
		return (
			<div className="py-4 footer">
				<div className="col-10 mx-auto p-0">
					<hr className="border-thick border-light p-0 m-0 col-12" />
					<span className="d-flex flex-row align-items-center justify-content-between mx-auto p-0 col-12">
						<div className="col-4 d-flex justify-content-between align-items-center">
							<img
								src={require("../assets/images/logo.svg")}
								className="float-left logo"
								onClick={this.home}
								type="button"
							/>
							<div style={{color: 'white', paddingTop: '25px'}} >
								<h5 style={{textAlign: 'center'}} ><Link style={{color: 'white'}} to='/wifi/terms-and-conditions' >{strings[language||'french'].terms}</Link></h5>
								<h5 style={{textAlign: 'center'}} ><Link style={{color: 'white'}} to='/wifi/privacy-policy' >{strings[language||'french'].policy}</Link></h5>
							</div>
						</div>
						<div className="col-8 d-flex flex-row align-items-center justify-content-between">
							<span className="d-flex flex-column mt-4">
								<img
									src={require("../assets/images/La FRENCH TECH Logo.svg")}
									className="sponsor"
								/>
								<img
									src={require("../assets/images/Schoolab Logo copy.svg")}
									className="sponsor"
								/>
							</span>
							<span className="mt-5 align-self-start text-light d-flex flex-column align-items-start justify-content-start">
								<p className="footer-head">France</p>
								<p className="footer-mini-text mt-2">
									6 Rue des Apennins <br />
									75017 Paris, France
								</p>
							</span>
							<span className="mt-5 align-self-start text-light d-flex flex-column align-items-start justify-content-start">
								<p className="footer-head">Contact</p>
								<h5>
									<Link style={{color: 'white'}} to='/contactus' >Nous Contacter</Link>
								</h5>
								{/* <a
									className="footer-mini-text mt-2 pointer text-light"
									onClick={this.contactUs}
									href={"/contactus"}
								>
									Nous Contacter
								</a> */}
							</span>
							<span className="mt-5 align-self-start text-light d-flex flex-column align-items-start justify-content-start">
								<span className="d-flex flex-row align-items-center justify-content-end">
									<a
										href={"https://twitter.com/AstrofiTech"}
										target="_black"
									>
										<Twitter className="mx-1 pointer footer-social twitter" />
									</a>
									<a
										href={
											"https://www.facebook.com/AstrofiTech/"
										}
										target="_black"
									>
										<Facebook className="mx-1 pointer footer-social facebook" />
									</a>
									<a
										href={
											"https://www.instagram.com/astrofi_tech/"
										}
										target="_black"
									>
										<Insta className="mx-1 pointer footer-social insta" />
									</a>
									<a
										href={
											"https://www.linkedin.com/company/astrofitech"
										}
										target="_black"
									>
										<LinkedIn className="mx-1 pointer footer-social linkedin" />
									</a>
								</span>
								<p className="footer-mini-text mt-4">
									Copyright © 2020 Astrofi.
								</p>
							</span>
						</div>
					</span>
				</div>
			</div>
		);
	}
}

export default Footer;
