import React, { Component } from "react";
import { InView } from 'react-intersection-observer'

class Card extends Component {
	state = { showDetails: false };

	// Deprecated
	toggleDetails = (e) => {
		if (e.target.type !== "submit" && e.target.id !== "info-link") {
			let newState = {};
			if (this.state.showDetails) {
				newState = {
					showDetails: false,
				};
			} else {
				newState = {
					showDetails: true,
					originalSize: {
						width: e.currentTarget.offsetWidth,
						height: e.currentTarget.offsetHeight,
					},
				};
			}
			this.setState(newState, this.handleResize);
		}
	};
	handleResize = () => {
		const element = this.tile;
		const { grid } = this.props;
		const { showDetails } = this.state;

		if (showDetails) {
			if (window.innerWidth < 768) {
				element.style.width = `${grid._width / 1.05}px`;
				element.style.height = `${window.innerHeight / 1.3}px`;
			} else {
				element.style.width = `${grid._width / 2.25}px`;
				element.style.height = `${grid._width / 3}px`;
			}
		} else {
			const { width, height } = this.state.originalSize;

			element.style.width = `${width}px`;
			element.style.height = `${height}px`;
		}
		// Refresh the changed item's cached
		// dimensions and do layout
		grid.refreshItems().layout();
	};
	renderDetails = () => {
		const { showDetails } = this.state;
		const { icon, title, size, iconStyle, detailsComponent } = this.props;

		return (
			<React.Fragment>
				<div
					className={`item-content ${
						showDetails
							? "card-drag-hidden"
							: `card-drag-${size}-content`
					} w-100`}
				>
					{icon}
					<p className="p-0 m-0 text-primary card-drag-title">
						{title}
					</p>
				</div>
				<div
					className={`item-content ${
						showDetails
							? `card-drag-${size}-content`
							: "card-drag-hidden"
					}`}
				>
					{detailsComponent}
				</div>
			</React.Fragment>
		);
	};
	render() {
		const { size, onClick, id } = this.props;

		return (
			<div
				className={`item shadow bg-light card-rouded m-2 card-drag-${size}`}
				id={id}
				onClick={onClick}
				ref={(ref) => (this.tile = ref)}
			>
				{this.renderDetails()}
			</div>
		);
	}
}

export default Card;
