export default {
	french: {
		terms: "Termes et conditions",
		policy: "Politique de confidentialité",
		getAstrofied: "S’ASTROFIER AVEC LA TECHNOLOGIE ET LA CRÉATIVITÉ!",
		whoWeAre: "QUI SOMMES NOUS?",
		weAreIndependent: `Nous sommes une Agence Tech indépendante spécialisée pour aider votre marque à offrir la meilleure expérience client, à la fois dans le monde physique et le monde numérique interactif. Si ces interactions sont en harmonie, le chemin de votre client est fluide et satisfaisante. La meilleure façon de vous aider à réussir est d'aider vos clients à réussir. 
Nous nous mettons dans la peau de vos clients, découvrons ce qui les fait réagir et leurs catalyseurs, et nous comprenons leurs moments de vérité. Nous sommes des facilitateurs, vous assistant ainsi que vos clients à naviguer dans le monde interactif de plus en plus complexe. C’est ce qui fait de nous de véritables spécialistes du marketing!`,
		mission: "MISSION",
		weCombine: `Nous combinons Media numérique, Technologie numérique et Créativité sur une seule plateforme avec un tableau de bord complet et transparent ce qui vous permettra, en tant que preneur de décision, de surveiller votre entreprise grâce à des cartes thermiques et des graphiques progressifs tout en profitant du confort de votre chaise.`,
		vision: "VISION",
		delivering: `Offrir au monde entier la technologie et la créativité pour aider autant d’entreprises que possible à améliorer leur image et à favoriser leurs succès.`,
		whatWeDo: `CE QUE NOUS FAISONS`,
		digitalExperience: `EXPÉRIENCES NUMÉRIQUES`,
		weveBeenThere: `Nous sommes là depuis le début et nous continuons de grandir en adoptant les dernières technologies pour présenter, promouvoir et élever votre marque en créant une expérience numérique inoubliable pour vous et vos clients.`,
		branding: `L'IMAGE DE MARQUE`,
		whetherPersonal: `Qu'elle soit personnelle ou d'entreprise, votre image de marque doit refléter votre identité, votre caractère et surtout, les produits ou services que vous offrez. C’est l’une de nos principales spécialités.`,
		storytelling: `NARRATION`,
		storytellingEssential: `La narration est une activité humaine essentielle et clé dans le marketing de contenu, l'image de marque et plus encore. Nous savons ce qu'il faut pour raconter une bonne histoire sur votre marque.`,
		contactUs: "Nous Contacter",
		aboutUs: "À propos de nous",
		services: "Services",
		missionVision: "Mission et Vision",
		contactUsNow: "Nous Contacter",
		checkPortfolio: "VÉRIFIEZ LE PORTEFEUILLE",
		Affaires: "Affaires",
		portefeuille: "PORTEFEUILLE",
		portfolioPar: `Avoir un site Web ne consiste plus à avoir une adresse en ligne, c’est bien plus que cela. Il s’agit d’être présent dans le monde numérique qui est le monde dans lequel nous vivons, il s’agit de se connecter avec les gens et d’entrer dans leurs maisons, leurs mains et leurs pensées, il s’agit de partager vos idées, produits et services avec le monde; et enfin et surtout, il s’agit de faire croître votre entreprise au-delà des frontières et des limites traditionnelles.`,
	},
	english: {
		terms: "Terms and conditions",
		policy: "Privacy Policy",
		getAstrofied: "GET ASTROFIED WITH TECH & CREATIVITY!",
		whoWeAre: "WHO WE ARE?",
		weAreIndependent: `We are an independent Tech Agency specialized in get to the heart of your customer’s experience with your brand; both, in the physical world and the interactive world. If these interactions are in harmony, your customer’s journey is seamless and satisfying. The best way to help you succeed is to help your customers succeed.

We walk in your customers’ shoes, uncover their triggers and drivers, understand their moments of truth. We are enablers, helping you and your customers navigate an increasingly complex interactive world, and that’s what makes us true passionate marketers!
`,
		mission: "MISSION",
		weCombine: `We combine “Digital Media”, “Digital Tech” and “Creativity” all on one platform with a comprehensive and seamless dashboard that would allow you, the decision maker, to monitor your business through heat maps and progressive charts from the comfort of your chair. `,
		vision: "VISION",
		delivering: `Delivering a whole world full of technology and creativity to help you improve your image and to grow.`,
		whatWeDo: `WHAT WE DO?`,
		digitalExperience: `DIGITAL EXPERIENCES`,
		weveBeenThere: `We’ve been there since it all started, and continue to grow embracing the latest technologies to showcase, promote and uplift your brand by creating an unforgettable digital experience for you, and your customers.`,
		branding: `BRANDING`,
		whetherPersonal: `Whether it’s personal or corporate, your branding has to reflect your identity, character and most importantly, the products or services you possess. That’s one of our core specialties.`,
		storytelling: `STORYTELLING`,
		storytellingEssential: `Storytelling is an essential human activity and key in content marketing, branding and beyond. We know what it takes to tell a good story about your brand.`,
		contactUs: "Contact Us",
		aboutUs: "About Us",
		services: "Services",
		missionVision: "Mission & Vision",
		contactUsNow: "Contact Us Now",
		checkPortfolio: "CHECK PORTFOLIO",
		Affaires: "Business",
		portefeuille: "PORTFOLIO",
		portfolioPar: `Having a website is no longer about having an address online, it's much more than that. It's about being present in the digital world which is the world we live in, it's about connecting with people and getting into their homes, their hands and their thoughts, it's about share your ideas, products and services with the world; and last but not least, it’s about growing your business beyond traditional borders and boundaries.`,
	},
};
