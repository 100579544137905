import React, { Component } from "react";
import { CloudTop, Rocket, Smoke, SpaceMan } from "./partials/space.js";

import RocketSVG from "../assets/images/rocket.svg";
import SpaceManSVG from "../assets/images/spacen-man.svg";
import CloudBottomSVG from "../assets/images/cloud-bottom.svg";

import strings from "../resources/strings.js";

export default class About extends Component {
	componentDidMount() {}
	render() {
		const { language } = this.props;
		return (
			<div className="about">
				<CloudTop className="cloud-top" />
				<img src={RocketSVG} className="rocket" />
				<div className="container mt-5 col-11">
					<h1 className="text-light col-6 title">
						{strings[language||'french'].getAstrofied}
					</h1>
				</div>
				<div className="empty-block"></div>
				<Smoke className="smoke" />
				<img src={SpaceManSVG} className="space-man" />
				<div className="container mt-5 col-11">
					<div className="col-6 float-right">
						<h1 className="text-light title">{strings[language||'french'].whoWeAre}</h1>
						<p className="text-light text">
							{strings[language||'french'].weAreIndependent}
						</p>
					</div>
				</div>
				<div className="empty-block"></div>
				<div className="empty-block-half"></div>
				<div className="empty-block-half"></div>
				<img src={CloudBottomSVG} className="cloud-bottom" />
			</div>
		);
	}
}
