import React, { Component } from 'react';
import history from '../router/history.js';
import { withRouter } from 'react-router-dom';

export const DIGITAL_MARKETING = 'MARKETING NUMÉRIQUE';
export const PRODUCTION = 'PRODUCTION';
export const WEB_DEVELOPMENT = 'DÉVELOPPEMENT DE SITE WEB';
export const SEO_ASO = 'SEO ET ASO';
export const HOSTING_EMAIL = 'HÉBERGEMENT WEB ET SERVICE EMAIL';
export const SMS_EMAIL = 'SMS ET EMAIL MARKETING';
export const UI_UX_DESIGN = 'DÉVELOPPEMENT D’ INTERFACE UTILISATEUR';
export const APP_DEV = 'DÉVELOPPEMENT D’APPLICATION MOBILE';
export const VR_AR = 'RÉALITÉ VIRTUELLE ET RÉALITÉ AUGMENTÉE';
export const WIFI_SOLUTIONS = 'WI-FI SOLUTIONS';

export class ContentCardBare extends Component {
  contactUs = title => {
    this.props.history.replace({
      pathname: '/contactus',
      state: { title },
    });
    this.props.closeModals();
  };
  goToExternal = () => {
    const { link } = this.props.externalButton;
    window.open(link);
  };
  render() {
    const { title, link, content, button, externalButton } = this.props;

    return (
      <React.Fragment>
        <div className={`col-12 mx-auto h-100`}>
          <div className={`text-primary font-weight-bold h6 my-3 ${title.className}`}>{title.text}</div>
          {content}
          <div className='d-flex flex-row justify-content-between align-items-center col-12 w-100 mt-3'>
            {externalButton && (
              <button
                className={`col-12 mx-auto btn btn-md gradient-button btn-rounded text-light shadow ${button.className}`}
                onClick={this.goToExternal}
              >
                {externalButton.text}
              </button>
            )}
          </div>
          <div className='d-flex flex-row justify-content-between align-items-center col-12 w-100 mt-3'>
            <button
              className={`col-12 mx-auto btn btn-md gradient-button btn-rounded text-light shadow ${button.className}`}
              onClick={() => this.contactUs(title.text)}
            >
              {button.text}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const ContentCard = withRouter(ContentCardBare);

export const DigitalMarketing = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: DIGITAL_MARKETING }}
      content={
        <p className='text-dark text-paragraph overflow-auto h-100'>
          Établissez des relations originales avec le public grâce à des solutions stratégiques axées sur les
          connaissances (data collection).
          <br />
          Nous gérons vos comptes de réseaux sociaux. nous organisons des campagnes marketing et des activations.
          <br />
          <br />
          Nous sommes là pour vous aider.
          <br />
          <br />
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </p>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const Production = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: PRODUCTION }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-100'>
          Ready, set, Shoot Connectez-vous avec vos clients en partageant un message simple et compréhensible. Nous
          investissons dans des stratégies sociales et des idées numériques qui résolvent des problèmes commerciaux
          complexes.
          <br />
          <br />
          Notre Opération en interne garantira une communication fluide et une livraison rapide. VIDEO PRODUCTION
          Tournage, Montage et animation, tout en interne.
          <br />
          <br />
          SOCIAL CONTENT & ASSETS Images, videos, GIFs, avec une pincée d’extraordinaire.
          <br />
          <br /> Nous sommes là pour vous aider.
          <br />
          <br />
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </div>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const WebDevelpment = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: WEB_DEVELOPMENT }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-100'>
          Créons des expériences significatives, la clé est d’écouter, de s’engager et de construire des relations.
          <br />
          <br />
          Nous sommes là pour vous aider.
          <br />
          <br />
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </div>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const Seo = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: SEO_ASO }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-100'>
          Gagnez la bataille de l’optimisation! Le SEO et l’ASO sont d’excellents moyens d’augmenter vos chances
          d’optimisation pour une meilleure visibilité dans les moteurs de recherche et dans les app stores.
          <br />
          <br /> Nous sommes là pour vous aider.
          <br />
          <br />
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </div>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const HostingEmail = ({ closeModals }) => {
  return (
    <ContentCard
      title={{
        text: HOSTING_EMAIL,
        className: 'mini-header',
      }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-100'>
          Il ne s'agit pas seulement de se battre pour vous sur le front, mais aussi d’assurer vos arrières, et c'est là
          que nos serveurs solides et fiables entrent en jeu avec notre support 24h sur 24 et 7j sur 7.
          <br />
          <br />
          On est là pour vous aider.
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </div>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const VR = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: VR_AR }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-100'>
          Nous convertissons votre idée en une réalité bien conçue.
          <br />
          Réalité Virtuelle et Vidéo 360°
          <br />
          Réalité Augmentée
          <br />
          Animation 3D
          <br />
          <br />
          Nous ne cessons d’innover, de concevoir et de développer des prototypes et de publier des applications à fort
          potentiel.
          <br />
          <br />
          Nous sommes là pour vous aider.
          <br />
          <br />
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </div>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const Design = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: UI_UX_DESIGN }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-100'>
          La façon dont les informations sont présentées est un facteur décisif important de l’engagement du contenu.
          Donc il est crucial de rendre le contenu de votre site Web ou application mobile facilement accessible.
          <br />
          <br />
          Nous sommes là pour vous aider.
          <br />
          <br />
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </div>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const AppDev = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: APP_DEV }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-100'>
          Vous n'avez pas encore d'application? Vous perdez votre place dans le monde numérique.
          <br />
          Les gens passent les 3/4 de leur temps sur les applications mobiles. Le développement d'applications mobiles
          est donc inévitable pour les entreprises.
          <br />
          <br />
          Nous sommes là pour vous aider.
          <br />
          <br />
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </div>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const SMS = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: SMS_EMAIL }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-100'>
          Concentrez Vous sur l’objectif: l’expansion de votre entreprise. Ciblez vos clients potentiels avec des
          données précises.
          <br />
          <br />
          Nous sommes là pour vous aider.
          <br />
          <br />
          {/*
						<a className="text-primary mini-link">+ More Info</a>
					*/}
        </div>
      }
      button={{ text: 'Nous Contacter' }}
      closeModals={closeModals}
    />
  );
};

export const Router = ({ closeModals }) => {
  return (
    <ContentCard
      title={{ text: WIFI_SOLUTIONS, className: 'mini-header' }}
      content={
        <div className='text-dark text-paragraph overflow-auto h-75'>
          Exploitez votre réseau WiFi gratuit!
          <br />
          <br />
          Astrofi transforme votre WiFi gratuit en un puissant outil de marketing qui construit votre base de données
          clients, promeut votre site et recueille des informations de valeur. Satisfaisez vos clients tout en obtenant
          des informations précieuses sur l'interface de votre business comme jamais auparavant. <br />
          <br />
          Pourquoi WiFi alors que vous pouvez Astrofi?
          <br />
          <div className='mt-2 col-12'>
            <img width={128} className='d-block mx-auto' src={require('../assets/images/Astrofi Logo.svg')} />
          </div>
        </div>
      }
      link={{ text: '+ More Info' }}
      button={{ text: 'Nous Contacter' }}
      externalButton={{
        text: 'visitez le site web',
        link: 'https://astrofi.com/socialwifi',
      }}
      closeModals={closeModals}
    />
  );
};
