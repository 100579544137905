import React from "react";

import VRIcon from "../assets/images/VRIcon.js";
import DigitalMarketingIcon from "../assets/images/DigitalMarketingIcon.js";
import ProductionIcon from "../assets/images/ProductionIcon.js";
import SEOIcon from "../assets/images/SEOIcon.js";
import WebDevIcon from "../assets/images/WebDevIcon.js";
import EmailIcon from "../assets/images/EmailIcon.js";
import UIIcon from "../assets/images/UIIcon.js";
import AppDevIcon from "../assets/images/AppDevIcon.js";
import SMSIcon from "../assets/images/SMSIcon.js";
import RouterIcon from "../assets/images/routerIcon.js";

import {
	DigitalMarketing,
	Production,
	WebDevelpment,
	Seo,
	HostingEmail,
	VR,
	Design,
	AppDev,
	SMS,
	Router,
} from "./services.js";

import {
	DIGITAL_MARKETING,
	PRODUCTION,
	SEO_ASO,
	WEB_DEVELOPMENT,
	HOSTING_EMAIL,
	SMS_EMAIL,
	UI_UX_DESIGN,
	APP_DEV,
	VR_AR,
	WIFI_SOLUTIONS,
} from "./services.js";

export default [
	{
		icon: (
			<div className="w-75 WIFIIcon" id="wifi-lottie">
				{" "}
			</div>
		),
		title: WIFI_SOLUTIONS,
		size: "lg",
		detailsComponent: <Router />,
		key: "WIFI",
		lottie: "/assets/lottie/Wifi-Animation/data.json",
	},
	{
		icon: (
			<div className="w-75 DigitalMarket" id="digital-market-lottie">
				{" "}
			</div>
		),
		title: DIGITAL_MARKETING,
		size: "lg",
		detailsComponent: <DigitalMarketing />,
		key: "DM",
		lottie: "/assets/lottie/DigitalMarketingIcon-Animation/data.json",
	},
	{
		icon: (
			<div className="w-75 Production" id="production-lottie">
				{" "}
			</div>
		),
		title: PRODUCTION,
		size: "lg",
		detailsComponent: <Production />,
		key: "PROD",
		lottie: "/assets/lottie/ProductionIcon-Animation/data.json",
	},
	{
		icon: (
			<div className="w-75 WebDev" id="webdev-lottie">
				{" "}
			</div>
		),
		title: WEB_DEVELOPMENT,
		size: "lg",
		detailsComponent: <WebDevelpment />,
		key: "WEB_DEV",
		lottie: "/assets/lottie/Web-Animation/data.json",
	},
	{
		icon: (
			<div className="w-75 SEO" id="seo-lottie">
				{" "}
			</div>
		),
		title: SEO_ASO,
		size: "lg",
		detailsComponent: <Seo />,
		key: "SEO",
		lottie: "/assets/lottie/SEO-Animation/data.json",
	},
	{
		icon: (
			<div className="w-50 EmailIcon" id="email-lottie">
				{" "}
			</div>
		),
		title: HOSTING_EMAIL,
		size: "sm",
		detailsComponent: <HostingEmail />,
		key: "HOST",
		lottie: "/assets/lottie/EmailServiceIcon-Animation/data.json",
	},
	{
		icon: (
			<div className="w-50 VRIcon" id="vricon-lottie">
				{" "}
			</div>
		),
		title: VR_AR,
		size: "sm",
		detailsComponent: <VR />,
		key: "VR",
		lottie: "/assets/lottie/VR-Animation/data.json",
	},
	{
		icon: (
			<div className="w-75 UIIcon" id="ui-lottie">
				{" "}
			</div>
		),
		title: UI_UX_DESIGN,
		size: "lg",
		detailsComponent: <Design />,
		key: "UI",
		lottie: "/assets/lottie/UI-Animation/data.json",
	},
	{
		icon: (
			<div className="w-75 AppDevIcon" id="appdev-lottie">
				{" "}
			</div>
		),
		title: APP_DEV,
		size: "lg",
		detailsComponent: <AppDev />,
		key: "APP_DEV",
		lottie: "/assets/lottie/AppDevIcon-Animation/data.json",
	},
	{
		icon: (
			<div className="w-75 SMSIcon" id="sms-lottie">
				{" "}
			</div>
		),
		title: SMS_EMAIL,
		size: "lg",
		detailsComponent: <SMS />,
		key: "SMS",
		lottie: "/assets/lottie/SMS-Animation/data.json",
	},
];
