import React, { Component } from "react";
import { CloudTop, Rocket, Smoke, SpaceMan } from "./partials/space.js";

import { Planets } from "./partials/space.js";

import strings from "../resources/strings.js";

import {transitionState} from '../utils';

const data = {
	french: [
		{ title: strings["french"].whatWeDo, par: "" },
		{
			title: strings["french"].digitalExperience,
			par: strings["french"].weveBeenThere,
		},
		{
			title: strings["french"].branding,
			par: strings["french"].whetherPersonal,
		},
		{
			title: strings["french"].storytelling,
			par: strings["french"].storytellingEssential,
		},
	],
	english: [
		{ title: strings["english"].whatWeDo, par: "" },
		{
			title: strings["english"].digitalExperience,
			par: strings["english"].weveBeenThere,
		},
		{
			title: strings["english"].branding,
			par: strings["english"].whetherPersonal,
		},
		{
			title: strings["english"].storytelling,
			par: strings["english"].storytellingEssential,
		},
	],
};

export default class WhatWeDo extends Component {
	state = {
		currentSlide: 0,
		show: true,
		par: "",
		title: data[this.props.language || "french"][0].title,
	};
	componentDidMount() {
		setInterval(this.loop, 2000);
	}
	loop = () => {
		const { language } = this.props;

		transitionState(
			() => this.setState({ show: false }),
			() => {
				let currentSlide = 0;
				if (
					this.state.currentSlide ===
					data[language || "french"].length - 1
				)
					currentSlide = 0;
				else currentSlide = this.state.currentSlide + 1;

				const { par, title } = data[language || "french"][currentSlide];

				this.setState({ show: true, par, title, currentSlide });
			}
		);
	};
	render() {
		const { language } = this.props;
		const { show, par, title, currentSlide } = this.state;
		return (
			<div className="col-11 mx-auto what-we-do d-flex flex-row align-items-center justify-content-between">
				<div className="mx-auto slide-container">
					<div className={`slide ${show ? "show" : "hide"}`}>
						<span className="text-light title">{title}</span>
						<p className="col-11 text text-light p-0">{par}</p>
						{par && (
							<a
								className="btn rounded text-primary btn-light shadow mt-5"
								href="#portfolio"
							>
								<img
									width={80}
									src={require("../assets/images/portfolio.svg")}
								/>
								{strings[language || "french"].checkPortfolio}
							</a>
						)}
					</div>
				</div>
				<Planets index={currentSlide} />
			</div>
		);
	}
}
