import React, { Component } from 'react'
import WhatsappSVG from '../assets/images/whatsapp.svg'

import strings from '../resources/strings.js'

const CONTACTS = [
    {
        name: 'whatsapp',
        contactDetail: {
            link: 'https://web.whatsapp.com/send?phone=+33632457265',
        },
    },
    {
        name: 'messenger',
        contactDetail: {
            link: 'http://m.me/AstrofiTech',
        },
    },
]

class ContactusBubble extends Component {
    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef()
        this.state = { current: 0, popupShow: false, showCard: false }
    }
    componentDidMount() {
        setInterval(this.loop, 2000)
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }
    loop = () => {
        if (this.state.showCard || this.state.popupShow) return
        else {
            this.setState(prevState => {
                const { current } = this.state
                let next = 0
                if (current === CONTACTS.length - 1) {
                    next = 0
                } else {
                    next = current + 1
                }
                return { current: next }
            })
        }
    }
    setWrapperRef = node => {
        this.wrapperRef = node
    }
    hideMenu = () => {
        this.setState({ showCard: false })
    }
    handleClickOutside = event => {
        const target = event.target
        const parent = target.parentElement
        if (parent.id !== 'contactus-floating') {
            if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
                this.hideMenu()
            }
        }
    }
    select = index => {
        this.setState({ current: index })
    }
    selectOpen = index => {
        this.setState({ current: index, showCard: true })
    }
    popup = () => {
        const { showCard } = this.state
        if (showCard) return
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.setState({
            popupShow: true,
            current: CONTACTS.length - 1,
        })
    }
    hide = () => {
        this.timer = setTimeout(() => this.setState({ popupShow: false }), 500)
    }
    showCard = () => {
        this.setState({ showCard: true, popupShow: false })
    }
    hideCard = () => {
        this.setState({ showCard: false })
    }
    renderCard = () => {
        const { showCard, current } = this.state
        const { name, contactDetail } = CONTACTS[current]

        return (
            <div className={`${name}-card ${showCard ? 'card-visible' : ''}`} ref={this.setWrapperRef}>
                <div className='contact-head'>
                    <img className='background' src={require('../assets/images/comets.svg')} />
                    <div className='d-flex flex-row align-items-center'>
                        <i
                            type='button'
                            onClick={this.hideCard}
                            className='fas fa-chevron-left float-left text-light'
                        ></i>
                        <span className='image-container mx-auto'>
                            <div className='image-placeholder'></div>
                        </span>
                    </div>
                    <p className='text-light text-center my-3'>
                        We are here to help you! Do not hesitate to ask us anything. Click below to start
                        chat.
                    </p>
                </div>
                <div className='contact-body'>
                    <div className='contact' onClick={() => window.open(contactDetail.link)}>
                        <span className='logo-container mr-3'>
                            <img src={require('../assets/images/logo.svg')} />
                        </span>
                        <span>
                            <div className='mini-text text-secondary'>Online Support</div>
                            <div className='font-weight-bold text-dark'>Michel</div>
                            <div className='mini-text text-secondary'>
                                online <i className='fas fa-dot-circle text-success'></i>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { current, popupShow, showCard } = this.state
        const { name } = CONTACTS[current]
        const { language } = this.props

        return (
            <React.Fragment>
                <div
                    className={`${name}-button`}
                    onMouseLeave={this.hide}
                    onClick={this.showCard}
                    id='contactus-floating'
                >
                    <div className={`popup ${popupShow ? '' : 'popup-hidden'}`}>
                        {CONTACTS.map((contact, index) => (
                            <img
                                key={contact.name}
                                onMouseOver={() => this.select(index)}
                                onClick={() => this.selectOpen(index)}
                                className='icon-placeholder my-2'
                                src={require(`../assets/images/${contact.name}.svg`)}
                            />
                        ))}
                    </div>
                    {showCard ? (
                        <div className='popup-row'>
                            {CONTACTS.map((contact, index) => (
                                <img
                                    key={contact.name}
                                    onMouseOver={() => this.select(index)}
                                    onClick={() => this.selectOpen(index)}
                                    className='icon-placeholder mx-2'
                                    src={require(`../assets/images/${contact.name}.svg`)}
                                />
                            ))}
                        </div>
                    ) : (
                        <span className={'text-container'}>{strings[language || 'french'].contactUsNow}</span>
                    )}
                    <span className='icon-container' onMouseOver={this.popup}>
                        <div className='icon-placeholder' onMouseOver={this.popup}></div>
                    </span>
                </div>
                {this.renderCard()}
            </React.Fragment>
        )
    }
}

export default ContactusBubble
