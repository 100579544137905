import React, { Component } from "react";
import { Navbar, Nav, Button, Modal, Form, FormControl } from "react-bootstrap";
import history from "../router/history.js";
import SwipeJS from "swipejs";

import {
	DigitalMarketing,
	Production,
	WebDevelpment,
	Seo,
	HostingEmail,
	VR,
	Design,
	AppDev,
	SMS,
	Router
} from "./services.mobile.js";

import CARDS from "./cards.data.mobile.js";

class Tabber extends Component {
	state = {
		showServices: false,
		showSearch: false,
		showSocial: false,
		currentCard: null,
		showModal: false,
		search: ""
	};
	componentDidMount() {
		this.mySwipe = null;
	}
	initSwiper = () => {
		this.mySwipe = new SwipeJS(document.getElementById("social-slider"));
	};
	toggleShowServices = () => {
		this.setState({
			showServices: !this.state.showServices,
			showSearch: false,
			showSocial: false
		});
	};
	toggleShowSearch = () => {
		this.setState({
			showSearch: !this.state.showSearch,
			showServices: false,
			showSocial: false
		});
	};
	toggleShowSocial = () => {
		this.setState(
			{
				showSocial: !this.state.showSocial,
				showServices: false,
				showSearch: false
			},
			this.initSwiper
		);
	};
	closeTabber = () => {
		this.setState({
			showSocial: false,
			showServices: false,
			showSearch: false
		});
	};
	goHome = () => {
		this.setState({
			showServices: false,
			showSearch: false,
			showSocial: false
		});
		history.push("/");
	};
	goCards = () => {
		this.setState({
			showServices: false,
			showSearch: false,
			showSocial: false
		});
		history.push("/cards");
	};
	showModal = card => {
		this.setState({ currentCard: card, showModal: true });
	};
	hideModal = card => {
		this.setState({ currentCard: null, showModal: false });
	};
	handleSearch = e => {
		this.setState({ search: e.target.value });
	};
	renderModal = () => {
		const { currentCard, showModal, showSearch } = this.state;

		if (!currentCard) return;

		const detailsComponent = React.cloneElement(
			currentCard.detailsComponent,
			{
				closeModals: () => {
					this.hideModal();
					this.closeTabber();
				}
			}
		);
		return (
			<Modal show={showModal} onHide={this.hideModal} centered>
				<Modal.Body>{detailsComponent}</Modal.Body>
			</Modal>
		);
	};
	renderServicesList = () => {
		const { showServices, showSearch, showSocial, search } = this.state;
		if (showServices) {
			return (
				<React.Fragment>
					<div className="mx-auto col-11 mt-3 d-flex flex-row align-items-center justify-content-around mb-4">
						<p className="h5 text-primary col-10 p-0 mb-0">
							{"LES SERVICES"}
						</p>
						<span className="col-2">
							<img
								width={16}
								src={require("../assets/images/close.svg")}
								onClick={this.closeTabber}
							/>
						</span>
					</div>
					{CARDS.map((card, index) => (
						<p
							onClick={() => this.showModal(card)}
							className={"h6 font-weight-normal my-3 col-10 mx-auto"}
							key={index.toString()}
						>
							{card.title}
						</p>
					))}
				</React.Fragment>
			);
		} else if (showSearch) {
			const results = CARDS.filter(
				card =>
					card.title.toLowerCase().search(search.toLowerCase()) >= 0
			);
			return (
				<React.Fragment>
					<div className="mx-auto col-11 mt-3 d-flex flex-row align-items-center justify-content-around mb-4">
						<p className="h5 text-primary col-10 p-0 mb-0">
							{"CHERCHER"}
						</p>
						<span className="col-2">
							<img
								width={16}
								src={require("../assets/images/close.svg")}
								onClick={this.closeTabber}
							/>
						</span>
					</div>
					<div className="col-12 d-flex flex-row align-items-center justify-content-center">
						<input
							className={"rouded mini-text col-11 search-input"}
							type="search"
							value={search}
							onChange={this.handleSearch}
							placeholder={"Search for services…"}
						/>
					</div>
					{results.map((card, index) => (
						<p
							onClick={() => this.showModal(card)}
							className={"h6 my-3 col-10 mx-auto"}
							key={index.toString()}
						>
							{card.title}
						</p>
					))}
				</React.Fragment>
			);
		} else if (showSocial) {
			return (
				<React.Fragment>
					<div className="mx-auto col-11 mt-3 d-flex flex-row align-items-center justify-content-around mb-4">
						<p className="h5 text-primary col-10 p-0 mb-0">
							{"FOLLOW US"}
						</p>
						<span className="col-2">
							<img
								width={16}
								src={require("../assets/images/close.svg")}
								onClick={this.closeTabber}
							/>
						</span>
					</div>
					<div className=" mt-3">
						<div className="d-flex flex-column align-items-center justify-content-center">
							<div className="social-media d-flex flex-row col-12 align-items-center justify-content-between">
								<a
									href={
										"https://www.facebook.com/AstrofiTech/"
									}
									target={"_blank"}
								>
									<img
										src={require("../assets/images/facebook-mobile.svg")}
										className="mx-auto d-block w-75"
									/>
								</a>
								<a
									href={"https://twitter.com/AstrofiTech"}
									target={"_blank"}
								>
									<img
										src={require("../assets/images/twitter-mobile.svg")}
										className="mx-auto d-block w-75"
									/>
								</a>
							</div>
							<div className="social-media  d-flex flex-row col-12 align-items-center justify-content-between mt-5">
								<a
									href={
										"https://www.instagram.com/astrofi_tech/"
									}
									target={"_blank"}
								>
									<img
										src={require("../assets/images/insta-mobile.svg")}
										className="mx-auto d-block w-75"
									/>
								</a>
								<a
									href={
										"https://www.linkedin.com/company/astrofitech"
									}
									target={"_blank"}
								>
									<img
										src={require("../assets/images/linkedin-mobile.svg")}
										className="mx-auto d-block w-75"
									/>
								</a>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		} else return <div></div>;
	};
	render() {
		const { showServices, showSearch, showSocial } = this.state;
		const tabStyle =
			showSearch || showServices || showSocial
				? "tabber-expand"
				: "tabber-collapse";

		return (
			<div className={`tabber fixed-bottom ${tabStyle}`}>
				{showSearch || showServices || showSocial ? (
					<div></div>
				) : (
					<div className="d-flex flex-row w-100 align-items-center justify-content-around mb-4">
						<img
							src={require("../assets/images/home.svg")}
							onClick={this.goHome}
						/>
						<img
							src={require("../assets/images/bars.svg")}
							onClick={this.toggleShowServices}
						/>
						<img
							src={require("../assets/images/search.svg")}
							onClick={this.toggleShowSearch}
						/>
						<img
							src={require("../assets/images/notif.svg")}
							onClick={this.toggleShowSocial}
						/>
					</div>
				)}
				{this.renderServicesList()}
				{this.renderModal()}
			</div>
		);
	}
}

export default Tabber;
