import React, { Component } from "react";
import { Navbar, Nav, Button, Modal, Form, FormControl } from "react-bootstrap";
import Select from 'react-select';

import { sendEmail } from "../utils/";

import {
	DIGITAL_MARKETING,
	PRODUCTION,
	SEO_ASO,
	WEB_DEVELOPMENT,
	HOSTING_EMAIL,
	SMS_EMAIL,
	UI_UX_DESIGN,
	APP_DEV,
	VR_AR,
	WIFI_SOLUTIONS
} from "./services.mobile.js";

import ReCAPTCHA from "react-google-recaptcha";
import config from "../config.js";
import {NamePattern, EmailPattern} from './Patterns.js';

import {Countries} from './Countries.js';

class ContactUs extends Component {
	state = {
		showModal: false,
		service: "",
		name: "",
		email: "",
		phoneNumber: "",
		pays: "",
		address: "",
		companyName: "",
		terms: false,
		showRecaptcha: false
	};
	sendMessage = () => {
		const formValid = this.contactForm.reportValidity();
		if (formValid) {
			this.setState({ showRecaptcha: true });
		}
	};
	submitForm = () => {
		this.setState({ showModal: true, showRecaptcha: false }, async () => {
			if (this.state.pays){
				await sendEmail({...this.state, pays: this.state.pays.label});
			}else{
				await sendEmail({...this.state});
			}
			setTimeout(() => this.setState({ showModal: false }), 3000);
		});
	};
	handlePhoneNumber = e => {
		const stringLength = e.target.value.length;
		const lastChar = e.target.value[stringLength - 1] || 0;
		if (!isNaN(lastChar)) {
			this.setState({ phoneNumber: e.target.value });
		}
	};
	renderContact = () => {
		const { showModal } = this.state;
		return (
			<Modal
				show={showModal}
				onHide={() => this.setState({ showModal: false })}
			>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12 mx-auto">
							<img
								src={require("../assets/images/merci.svg")}
								width={200}
								className="mx-auto d-block"
							/>
							<h1 className="text-primary text-center font-weight-bold">
								MERCI!
							</h1>
							<p className="h6 text-shadytext text-center font-weight-bold">
								Votre demande a été reçue et nous vous <br />
								contacterons sous peu.
							</p>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="col-12 text-center">
						<p className="mini-text text-shadytext font-weight-bold">
							Si vous voulez parler à quelqu’un, n’hésitez pas à
							appeler. <br />
						</p>
					</div>
					<div className="col-12 text-center">
						<p className="mini-text text-primary">
							+33 6 32 45 72 65
						</p>
					</div>
				</Modal.Footer>
			</Modal>
		);
	};
	renderRecaptcha() {
		const { showRecaptcha } = this.state;
		return (
			<Modal
				show={showRecaptcha}
				onHide={() => this.setState({ showRecaptcha: false })}
			>
				<Modal.Body>
					<div className="row">
						<div className="col-md-10 mx-auto">
							<p>Veuillez cocher la case pour continuer</p>
							<div className="ml-4">
								<ReCAPTCHA
									sitekey={config.siteKey}
									onChange={this.submitForm}
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
	render() {
		const {
			name,
			phoneNumber,
			email,
			companyName,
			address,
			pays,
			terms,
			service
		} = this.state;

		return (
			<div className="py-4">
				<div className="container">
					{this.renderContact()}
					{this.renderRecaptcha()}
					<div className="row col-12 mx-auto">
						<div className="row col-lg-8 col-xl-8 col-12">
							<div className="contact-card bg-light shadow col-12">
								<form
									className="mx-auto my-5"
									onSubmit={e => e.preventDefault()}
									ref={ref => (this.contactForm = ref)}
								>
									<div className="form-group col-11 mx-auto">
										<span className="h4 text-shadytext">
											Envoyer un Message
										</span>
										<input
											type="text"
											className="form-control my-3"
											placeholder="Prénom/Nom*"
											required
											value={name}
											pattern={NamePattern}
											onChange={e =>
												this.setState({
													name: e.target.value
												})
											}
										/>
										<div className="d-flex flex-row">
											<input
												type="email"
												className="form-control my-3 mx-2"
												placeholder="E-mail"
												required
												value={email}
												pattern={EmailPattern}
												onChange={e =>
													this.setState({
														email: e.target.value
													})
												}
											/>
											<input
												type="tel"
												className="form-control my-3 mx-2"
												placeholder="Numéro de téléphone"
												value={phoneNumber}
												onChange={
													this.handlePhoneNumber
												}
											/>
										</div>
										<div className="d-flex flex-row align-items-center justify-content-between col-12 mx-auto px-1">
											<div className="w-50 my-3 px-0 mr-2">
												<Select
											        classNamePrefix="react-select"
											        options={Countries}
											        placeholder={"Pays"}
											        required
											        value={pays}
													onChange={e =>
														this.setState({
															pays: e
														})
													}
											      />
											</div>
											<div className="w-50 my-3 px-0 ml-2">
												<input
													type="text"
													className="form-control"
													placeholder="Ville"
													value={address}
													onChange={e =>
														this.setState({
															address: e.target.value
														})
													}
												/>
											</div>
										</div>
										<div className="d-flex flex-row">
											<input
												type="tel"
												className="form-control my-3 mx-2"
												placeholder="Nom de l’entreprise"
												value={companyName}
												onChange={e =>
													this.setState({
														companyName:
															e.target.value
													})
												}
											/>
											<input
												type="text"
												className="form-control my-3 mx-2"
												placeholder="Adresse de site (en option)"
											/>
										</div>

										<div className="text-start mini-text col-11 my-3 mx-auto d-flex flex-wrap flex-column radio-points">
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio1"
													name="customRadio"
													className="custom-control-input"
													checked={
														service ===
														DIGITAL_MARKETING
													}
													onChange={e =>
														this.setState({
															service: DIGITAL_MARKETING
														})
													}
													required
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio1"
												>
													{DIGITAL_MARKETING}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio2"
													name="customRadio"
													className="custom-control-input"
													checked={
														service === PRODUCTION
													}
													onChange={e =>
														this.setState({
															service: PRODUCTION
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio2"
												>
													{PRODUCTION}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio3"
													name="customRadio"
													className="custom-control-input"
													checked={
														service ===
														WEB_DEVELOPMENT
													}
													onChange={e =>
														this.setState({
															service: WEB_DEVELOPMENT
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio3"
												>
													{WEB_DEVELOPMENT}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio4"
													name="customRadio"
													className="custom-control-input"
													checked={
														service === APP_DEV
													}
													onChange={e =>
														this.setState({
															service: APP_DEV
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio4"
												>
													{APP_DEV}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio5"
													name="customRadio"
													className="custom-control-input"
													checked={
														service === UI_UX_DESIGN
													}
													onChange={e =>
														this.setState({
															service: UI_UX_DESIGN
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio5"
												>
													{UI_UX_DESIGN}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio6"
													name="customRadio"
													className="custom-control-input"
													checked={
														service === SEO_ASO
													}
													onChange={e =>
														this.setState({
															service: SEO_ASO
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio6"
												>
													{SEO_ASO}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio7"
													name="customRadio"
													className="custom-control-input"
													checked={service === VR_AR}
													onChange={e =>
														this.setState({
															service: VR_AR
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio7"
												>
													{VR_AR}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio8"
													name="customRadio"
													className="custom-control-input"
													checked={
														service ===
														HOSTING_EMAIL
													}
													onChange={e =>
														this.setState({
															service: HOSTING_EMAIL
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio8"
												>
													{HOSTING_EMAIL}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio9"
													name="customRadio"
													className="custom-control-input"
													checked={
														service === SMS_EMAIL
													}
													onChange={e =>
														this.setState({
															service: SMS_EMAIL
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio9"
												>
													{SMS_EMAIL}
												</label>
											</div>
											<div className="custom-control custom-radio my-1">
												<input
													type="radio"
													id="customRadio10"
													name="customRadio"
													className="custom-control-input"
													checked={
														service ===
														WIFI_SOLUTIONS
													}
													onChange={e =>
														this.setState({
															service: WIFI_SOLUTIONS
														})
													}
												/>
												<label
													className="custom-control-label"
													htmlFor="customRadio10"
												>
													{WIFI_SOLUTIONS}
												</label>
											</div>
										</div>

										<div className="custom-control custom-checkbox text-center">
											<input
												type="checkbox"
												className="custom-control-input"
												id="customCheck1"
												checked={terms}
												onChange={e =>
													this.setState({
														terms: !terms
													})
												}
												required
											/>
											<label
												className="custom-control-label text-primary font-weight-bold mini-text"
												htmlFor="customCheck1"
											>
												J’accepte la politique de
												confidentialité et les
												conditions d’utilisation de
												Astrofi Tech.
											</label>
										</div>
									</div>

									<div className="col-12 text-center">
										<button
											className="btn btn-md btn-primary btn-rounded text-light col-10"
											onClick={this.sendMessage}
											type="submit"
										>
											ENVOYER
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="row col-12 col-lg-3 col-xl-3 h-25 text-light ml-3 mt-5">
							<div className="h3">Nous Contacter</div>
							<p>
								info@Astrofi.com
								<br />
								<br />
								+33 6 32 45 72 65
								<br />
								<br />6 Rue des Apennins | 75017 Paris, France
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ContactUs;
