export const Countries = [
    {"code": "FR", "value": "FRA", "label": "France", "number": "250"},
    {"label": "Other Countries", "options":[
        {"code": "AF", "value": "AFG", "label": "Afghanistan", "number": "004"},
        {"code": "AL", "value": "ALB", "label": "Albania", "number": "008"},
        {"code": "DZ", "value": "DZA", "label": "Algeria", "number": "012"},
        {"code": "AS", "value": "ASM", "label": "American Samoa", "number": "016"},
        {"code": "AD", "value": "AND", "label": "Andorra", "number": "020"},
        {"code": "AO", "value": "AGO", "label": "Angola", "number": "024"},
        {"code": "AI", "value": "AIA", "label": "Anguilla", "number": "660"},
        {"code": "AQ", "value": "ATA", "label": "Antarctica", "number": "010"},
        {"code": "AG", "value": "ATG", "label": "Antigua and Barbuda", "number": "028"},
        {"code": "AR", "value": "ARG", "label": "Argentina", "number": "032"},
        {"code": "AM", "value": "ARM", "label": "Armenia", "number": "051"},
        {"code": "AW", "value": "ABW", "label": "Aruba", "number": "533"},
        {"code": "AU", "value": "AUS", "label": "Australia", "number": "036"},
        {"code": "AT", "value": "AUT", "label": "Austria", "number": "040"},
        {"code": "AZ", "value": "AZE", "label": "Azerbaijan", "number": "031"},
        {"code": "BS", "value": "BHS", "label": "Bahamas (the)", "number": "044"},
        {"code": "BH", "value": "BHR", "label": "Bahrain", "number": "048"},
        {"code": "BD", "value": "BGD", "label": "Bangladesh", "number": "050"},
        {"code": "BB", "value": "BRB", "label": "Barbados", "number": "052"},
        {"code": "BY", "value": "BLR", "label": "Belarus", "number": "112"},
        {"code": "BE", "value": "BEL", "label": "Belgium", "number": "056"},
        {"code": "BZ", "value": "BLZ", "label": "Belize", "number": "084"},
        {"code": "BJ", "value": "BEN", "label": "Benin", "number": "204"},
        {"code": "BM", "value": "BMU", "label": "Bermuda", "number": "060"},
        {"code": "BT", "value": "BTN", "label": "Bhutan", "number": "064"},
        {"code": "BO", "value": "BOL", "label": "Bolivia (Plurinational State of)", "number": "068"},
        {"code": "BQ", "value": "BES", "label": "Bonaire, Sint Eustatius and Saba", "number": "535"},
        {"code": "BA", "value": "BIH", "label": "Bosnia and Herzegovina", "number": "070"},
        {"code": "BW", "value": "BWA", "label": "Botswana", "number": "072"},
        {"code": "BV", "value": "BVT", "label": "Bouvet Island", "number": "074"},
        {"code": "BR", "value": "BRA", "label": "Brazil", "number": "076"},
        {"code": "IO", "value": "IOT", "label": "British Indian Ocean Territory (the)", "number": "086"},
        {"code": "BN", "value": "BRN", "label": "Brunei Darussalam", "number": "096"},
        {"code": "BG", "value": "BGR", "label": "Bulgaria", "number": "100"},
        {"code": "BF", "value": "BFA", "label": "Burkina Faso", "number": "854"},
        {"code": "BI", "value": "BDI", "label": "Burundi", "number": "108"},
        {"code": "CV", "value": "CPV", "label": "Cabo Verde", "number": "132"},
        {"code": "KH", "value": "KHM", "label": "Cambodia", "number": "116"},
        {"code": "CM", "value": "CMR", "label": "Cameroon", "number": "120"},
        {"code": "CA", "value": "CAN", "label": "Canada", "number": "124"},
        {"code": "KY", "value": "CYM", "label": "Cayman Islands (the)", "number": "136"},
        {"code": "CF", "value": "CAF", "label": "Central African Republic (the)", "number": "140"},
        {"code": "TD", "value": "TCD", "label": "Chad", "number": "148"},
        {"code": "CL", "value": "CHL", "label": "Chile", "number": "152"},
        {"code": "CN", "value": "CHN", "label": "China", "number": "156"},
        {"code": "CX", "value": "CXR", "label": "Christmas Island", "number": "162"},
        {"code": "CC", "value": "CCK", "label": "Cocos (Keeling) Islands (the)", "number": "166"},
        {"code": "CO", "value": "COL", "label": "Colombia", "number": "170"},
        {"code": "KM", "value": "COM", "label": "Comoros (the)", "number": "174"},
        {"code": "CD", "value": "COD", "label": "Congo (the Democratic Republic of the)", "number": "180"},
        {"code": "CG", "value": "COG", "label": "Congo (the)", "number": "178"},
        {"code": "CK", "value": "COK", "label": "Cook Islands (the)", "number": "184"},
        {"code": "CR", "value": "CRI", "label": "Costa Rica", "number": "188"},
        {"code": "HR", "value": "HRV", "label": "Croatia", "number": "191"},
        {"code": "CU", "value": "CUB", "label": "Cuba", "number": "192"},
        {"code": "CW", "value": "CUW", "label": "Curaçao", "number": "531"},
        {"code": "CY", "value": "CYP", "label": "Cyprus", "number": "196"},
        {"code": "CZ", "value": "CZE", "label": "Czechia", "number": "203"},
        {"code": "CI", "value": "CIV", "label": "Côte d'Ivoire", "number": "384"},
        {"code": "DK", "value": "DNK", "label": "Denmark", "number": "208"},
        {"code": "DJ", "value": "DJI", "label": "Djibouti", "number": "262"},
        {"code": "DM", "value": "DMA", "label": "Dominica", "number": "212"},
        {"code": "DO", "value": "DOM", "label": "Dominican Republic (the)", "number": "214"},
        {"code": "EC", "value": "ECU", "label": "Ecuador", "number": "218"},
        {"code": "EG", "value": "EGY", "label": "Egypt", "number": "818"},
        {"code": "SV", "value": "SLV", "label": "El Salvador", "number": "222"},
        {"code": "GQ", "value": "GNQ", "label": "Equatorial Guinea", "number": "226"},
        {"code": "ER", "value": "ERI", "label": "Eritrea", "number": "232"},
        {"code": "EE", "value": "EST", "label": "Estonia", "number": "233"},
        {"code": "SZ", "value": "SWZ", "label": "Eswatini", "number": "748"},
        {"code": "ET", "value": "ETH", "label": "Ethiopia", "number": "231"},
        {"code": "FK", "value": "FLK", "label": "Falkland Islands (the) [Malvinas]", "number": "238"},
        {"code": "FO", "value": "FRO", "label": "Faroe Islands (the)", "number": "234"},
        {"code": "FJ", "value": "FJI", "label": "Fiji", "number": "242"},
        {"code": "FI", "value": "FIN", "label": "Finland", "number": "246"},
        {"code": "GF", "value": "GUF", "label": "French Guiana", "number": "254"},
        {"code": "PF", "value": "PYF", "label": "French Polynesia", "number": "258"},
        {"code": "TF", "value": "ATF", "label": "French Southern Territories (the)", "number": "260"},
        {"code": "GA", "value": "GAB", "label": "Gabon", "number": "266"},
        {"code": "GM", "value": "GMB", "label": "Gambia (the)", "number": "270"},
        {"code": "GE", "value": "GEO", "label": "Georgia", "number": "268"},
        {"code": "DE", "value": "DEU", "label": "Germany", "number": "276"},
        {"code": "GH", "value": "GHA", "label": "Ghana", "number": "288"},
        {"code": "GI", "value": "GIB", "label": "Gibraltar", "number": "292"},
        {"code": "GR", "value": "GRC", "label": "Greece", "number": "300"},
        {"code": "GL", "value": "GRL", "label": "Greenland", "number": "304"},
        {"code": "GD", "value": "GRD", "label": "Grenada", "number": "308"},
        {"code": "GP", "value": "GLP", "label": "Guadeloupe", "number": "312"},
        {"code": "GU", "value": "GUM", "label": "Guam", "number": "316"},
        {"code": "GT", "value": "GTM", "label": "Guatemala", "number": "320"},
        {"code": "GG", "value": "GGY", "label": "Guernsey", "number": "831"},
        {"code": "GN", "value": "GIN", "label": "Guinea", "number": "324"},
        {"code": "GW", "value": "GNB", "label": "Guinea-Bissau", "number": "624"},
        {"code": "GY", "value": "GUY", "label": "Guyana", "number": "328"},
        {"code": "HT", "value": "HTI", "label": "Haiti", "number": "332"},
        {"code": "HM", "value": "HMD", "label": "Heard Island and McDonald Islands", "number": "334"},
        {"code": "VA", "value": "VAT", "label": "Holy See (the)", "number": "336"},
        {"code": "HN", "value": "HND", "label": "Honduras", "number": "340"},
        {"code": "HK", "value": "HKG", "label": "Hong Kong", "number": "344"},
        {"code": "HU", "value": "HUN", "label": "Hungary", "number": "348"},
        {"code": "IS", "value": "ISL", "label": "Iceland", "number": "352"},
        {"code": "IN", "value": "IND", "label": "India", "number": "356"},
        {"code": "ID", "value": "IDN", "label": "Indonesia", "number": "360"},
        {"code": "IR", "value": "IRN", "label": "Iran (Islamic Republic of)", "number": "364"},
        {"code": "IQ", "value": "IRQ", "label": "Iraq", "number": "368"},
        {"code": "IE", "value": "IRL", "label": "Ireland", "number": "372"},
        {"code": "IM", "value": "IMN", "label": "Isle of Man", "number": "833"},
        {"code": "IL", "value": "ISR", "label": "Israel", "number": "376"},
        {"code": "IT", "value": "ITA", "label": "Italy", "number": "380"},
        {"code": "JM", "value": "JAM", "label": "Jamaica", "number": "388"},
        {"code": "JP", "value": "JPN", "label": "Japan", "number": "392"},
        {"code": "JE", "value": "JEY", "label": "Jersey", "number": "832"},
        {"code": "JO", "value": "JOR", "label": "Jordan", "number": "400"},
        {"code": "KZ", "value": "KAZ", "label": "Kazakhstan", "number": "398"},
        {"code": "KE", "value": "KEN", "label": "Kenya", "number": "404"},
        {"code": "KI", "value": "KIR", "label": "Kiribati", "number": "296"},
        {"code": "KP", "value": "PRK", "label": "Korea (the Democratic People's Republic of)", "number": "408"},
        {"code": "KR", "value": "KOR", "label": "Korea (the Republic of)", "number": "410"},
        {"code": "KW", "value": "KWT", "label": "Kuwait", "number": "414"},
        {"code": "KG", "value": "KGZ", "label": "Kyrgyzstan", "number": "417"},
        {"code": "LA", "value": "LAO", "label": "Lao People's Democratic Republic (the)", "number": "418"},
        {"code": "LV", "value": "LVA", "label": "Latvia", "number": "428"},
        {"code": "LB", "value": "LBN", "label": "Lebanon", "number": "422"},
        {"code": "LS", "value": "LSO", "label": "Lesotho", "number": "426"},
        {"code": "LR", "value": "LBR", "label": "Liberia", "number": "430"},
        {"code": "LY", "value": "LBY", "label": "Libya", "number": "434"},
        {"code": "LI", "value": "LIE", "label": "Liechtenstein", "number": "438"},
        {"code": "LT", "value": "LTU", "label": "Lithuania", "number": "440"},
        {"code": "LU", "value": "LUX", "label": "Luxembourg", "number": "442"},
        {"code": "MO", "value": "MAC", "label": "Macao", "number": "446"},
        {"code": "MG", "value": "MDG", "label": "Madagascar", "number": "450"},
        {"code": "MW", "value": "MWI", "label": "Malawi", "number": "454"},
        {"code": "MY", "value": "MYS", "label": "Malaysia", "number": "458"},
        {"code": "MV", "value": "MDV", "label": "Maldives", "number": "462"},
        {"code": "ML", "value": "MLI", "label": "Mali", "number": "466"},
        {"code": "MT", "value": "MLT", "label": "Malta", "number": "470"},
        {"code": "MH", "value": "MHL", "label": "Marshall Islands (the)", "number": "584"},
        {"code": "MQ", "value": "MTQ", "label": "Martinique", "number": "474"},
        {"code": "MR", "value": "MRT", "label": "Mauritania", "number": "478"},
        {"code": "MU", "value": "MUS", "label": "Mauritius", "number": "480"},
        {"code": "YT", "value": "MYT", "label": "Mayotte", "number": "175"},
        {"code": "MX", "value": "MEX", "label": "Mexico", "number": "484"},
        {"code": "FM", "value": "FSM", "label": "Micronesia (Federated States of)", "number": "583"},
        {"code": "MD", "value": "MDA", "label": "Moldova (the Republic of)", "number": "498"},
        {"code": "MC", "value": "MCO", "label": "Monaco", "number": "492"},
        {"code": "MN", "value": "MNG", "label": "Mongolia", "number": "496"},
        {"code": "ME", "value": "MNE", "label": "Montenegro", "number": "499"},
        {"code": "MS", "value": "MSR", "label": "Montserrat", "number": "500"},
        {"code": "MA", "value": "MAR", "label": "Morocco", "number": "504"},
        {"code": "MZ", "value": "MOZ", "label": "Mozambique", "number": "508"},
        {"code": "MM", "value": "MMR", "label": "Myanmar", "number": "104"},
        {"code": "NA", "value": "NAM", "label": "Namibia", "number": "516"},
        {"code": "NR", "value": "NRU", "label": "Nauru", "number": "520"},
        {"code": "NP", "value": "NPL", "label": "Nepal", "number": "524"},
        {"code": "NL", "value": "NLD", "label": "Netherlands (the)", "number": "528"},
        {"code": "NC", "value": "NCL", "label": "New Caledonia", "number": "540"},
        {"code": "NZ", "value": "NZL", "label": "New Zealand", "number": "554"},
        {"code": "NI", "value": "NIC", "label": "Nicaragua", "number": "558"},
        {"code": "NE", "value": "NER", "label": "Niger (the)", "number": "562"},
        {"code": "NG", "value": "NGA", "label": "Nigeria", "number": "566"},
        {"code": "NU", "value": "NIU", "label": "Niue", "number": "570"},
        {"code": "NF", "value": "NFK", "label": "Norfolk Island", "number": "574"},
        {"code": "MP", "value": "MNP", "label": "Northern Mariana Islands (the)", "number": "580"},
        {"code": "NO", "value": "NOR", "label": "Norway", "number": "578"},
        {"code": "OM", "value": "OMN", "label": "Oman", "number": "512"},
        {"code": "PK", "value": "PAK", "label": "Pakistan", "number": "586"},
        {"code": "PW", "value": "PLW", "label": "Palau", "number": "585"},
        {"code": "PS", "value": "PSE", "label": "Palestine, State of", "number": "275"},
        {"code": "PA", "value": "PAN", "label": "Panama", "number": "591"},
        {"code": "PG", "value": "PNG", "label": "Papua New Guinea", "number": "598"},
        {"code": "PY", "value": "PRY", "label": "Paraguay", "number": "600"},
        {"code": "PE", "value": "PER", "label": "Peru", "number": "604"},
        {"code": "PH", "value": "PHL", "label": "Philippines (the)", "number": "608"},
        {"code": "PN", "value": "PCN", "label": "Pitcairn", "number": "612"},
        {"code": "PL", "value": "POL", "label": "Poland", "number": "616"},
        {"code": "PT", "value": "PRT", "label": "Portugal", "number": "620"},
        {"code": "PR", "value": "PRI", "label": "Puerto Rico", "number": "630"},
        {"code": "QA", "value": "QAT", "label": "Qatar", "number": "634"},
        {"code": "MK", "value": "MKD", "label": "Republic of North Macedonia", "number": "807"},
        {"code": "RO", "value": "ROU", "label": "Romania", "number": "642"},
        {"code": "RU", "value": "RUS", "label": "Russian Federation (the)", "number": "643"},
        {"code": "RW", "value": "RWA", "label": "Rwanda", "number": "646"},
        {"code": "RE", "value": "REU", "label": "Réunion", "number": "638"},
        {"code": "BL", "value": "BLM", "label": "Saint Barthélemy", "number": "652"},
        {"code": "SH", "value": "SHN", "label": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
        {"code": "KN", "value": "KNA", "label": "Saint Kitts and Nevis", "number": "659"},
        {"code": "LC", "value": "LCA", "label": "Saint Lucia", "number": "662"},
        {"code": "MF", "value": "MAF", "label": "Saint Martin (French part)", "number": "663"},
        {"code": "PM", "value": "SPM", "label": "Saint Pierre and Miquelon", "number": "666"},
        {"code": "VC", "value": "VCT", "label": "Saint Vincent and the Grenadines", "number": "670"},
        {"code": "WS", "value": "WSM", "label": "Samoa", "number": "882"},
        {"code": "SM", "value": "SMR", "label": "San Marino", "number": "674"},
        {"code": "ST", "value": "STP", "label": "Sao Tome and Principe", "number": "678"},
        {"code": "SA", "value": "SAU", "label": "Saudi Arabia", "number": "682"},
        {"code": "SN", "value": "SEN", "label": "Senegal", "number": "686"},
        {"code": "RS", "value": "SRB", "label": "Serbia", "number": "688"},
        {"code": "SC", "value": "SYC", "label": "Seychelles", "number": "690"},
        {"code": "SL", "value": "SLE", "label": "Sierra Leone", "number": "694"},
        {"code": "SG", "value": "SGP", "label": "Singapore", "number": "702"},
        {"code": "SX", "value": "SXM", "label": "Sint Maarten (Dutch part)", "number": "534"},
        {"code": "SK", "value": "SVK", "label": "Slovakia", "number": "703"},
        {"code": "SI", "value": "SVN", "label": "Slovenia", "number": "705"},
        {"code": "SB", "value": "SLB", "label": "Solomon Islands", "number": "090"},
        {"code": "SO", "value": "SOM", "label": "Somalia", "number": "706"},
        {"code": "ZA", "value": "ZAF", "label": "South Africa", "number": "710"},
        {"code": "GS", "value": "SGS", "label": "South Georgia and the South Sandwich Islands", "number": "239"},
        {"code": "SS", "value": "SSD", "label": "South Sudan", "number": "728"},
        {"code": "ES", "value": "ESP", "label": "Spain", "number": "724"},
        {"code": "LK", "value": "LKA", "label": "Sri Lanka", "number": "144"},
        {"code": "SD", "value": "SDN", "label": "Sudan (the)", "number": "729"},
        {"code": "SR", "value": "SUR", "label": "Surilabel", "number": "740"},
        {"code": "SJ", "value": "SJM", "label": "Svalbard and Jan Mayen", "number": "744"},
        {"code": "SE", "value": "SWE", "label": "Sweden", "number": "752"},
        {"code": "CH", "value": "CHE", "label": "Switzerland", "number": "756"},
        {"code": "SY", "value": "SYR", "label": "Syrian Arab Republic", "number": "760"},
        {"code": "TW", "value": "TWN", "label": "Taiwan (Province of China)", "number": "158"},
        {"code": "TJ", "value": "TJK", "label": "Tajikistan", "number": "762"},
        {"code": "TZ", "value": "TZA", "label": "Tanzania, United Republic of", "number": "834"},
        {"code": "TH", "value": "THA", "label": "Thailand", "number": "764"},
        {"code": "TL", "value": "TLS", "label": "Timor-Leste", "number": "626"},
        {"code": "TG", "value": "TGO", "label": "Togo", "number": "768"},
        {"code": "TK", "value": "TKL", "label": "Tokelau", "number": "772"},
        {"code": "TO", "value": "TON", "label": "Tonga", "number": "776"},
        {"code": "TT", "value": "TTO", "label": "Trinidad and Tobago", "number": "780"},
        {"code": "TN", "value": "TUN", "label": "Tunisia", "number": "788"},
        {"code": "TR", "value": "TUR", "label": "Turkey", "number": "792"},
        {"code": "TM", "value": "TKM", "label": "Turkmenistan", "number": "795"},
        {"code": "TC", "value": "TCA", "label": "Turks and Caicos Islands (the)", "number": "796"},
        {"code": "TV", "value": "TUV", "label": "Tuvalu", "number": "798"},
        {"code": "UG", "value": "UGA", "label": "Uganda", "number": "800"},
        {"code": "UA", "value": "UKR", "label": "Ukraine", "number": "804"},
        {"code": "AE", "value": "ARE", "label": "United Arab Emirates (the)", "number": "784"},
        {"code": "GB", "value": "GBR", "label": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826"},
        {"code": "UM", "value": "UMI", "label": "United States Minor Outlying Islands (the)", "number": "581"},
        {"code": "US", "value": "USA", "label": "United States of America (the)", "number": "840"},
        {"code": "UY", "value": "URY", "label": "Uruguay", "number": "858"},
        {"code": "UZ", "value": "UZB", "label": "Uzbekistan", "number": "860"},
        {"code": "VU", "value": "VUT", "label": "Vanuatu", "number": "548"},
        {"code": "VE", "value": "VEN", "label": "Venezuela (Bolivarian Republic of)", "number": "862"},
        {"code": "VN", "value": "VNM", "label": "Viet Nam", "number": "704"},
        {"code": "VG", "value": "VGB", "label": "Virgin Islands (British)", "number": "092"},
        {"code": "VI", "value": "VIR", "label": "Virgin Islands (U.S.)", "number": "850"},
        {"code": "WF", "value": "WLF", "label": "Wallis and Futuna", "number": "876"},
        {"code": "EH", "value": "ESH", "label": "Western Sahara", "number": "732"},
        {"code": "YE", "value": "YEM", "label": "Yemen", "number": "887"},
        {"code": "ZM", "value": "ZMB", "label": "Zambia", "number": "894"},
        {"code": "ZW", "value": "ZWE", "label": "Zimbabwe", "number": "716"},
        {"code": "AX", "value": "ALA", "label": "Åland Islands", "number": "248"}
    ]}
];
