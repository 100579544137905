import React, { Component } from "react";
import { Navbar, Nav, Button, Form, FormControl } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import history from "../router/history.js";

import menuWhite from "../assets/images/bars-white.svg";
import menuBlue from "../assets/images/bars.svg";

import strings from "../resources/strings.js";

const MENU = [
	{
		label: {
			french: strings["french"].services,
			english: strings["english"].services,
		},
		link: "#",
		icon: require("../assets/images/services.svg"),
	},
	{
		label: {
			french: strings["french"].aboutUs,
			english: strings["english"].aboutUs,
		},
		link: "#about",
		icon: require("../assets/images/about-us.svg"),
	},
	{
		label: {
			french: strings["french"].missionVision,
			english: strings["english"].missionVision,
		},
		link: "#mission",
		icon: require("../assets/images/mission.svg"),
	},
	{
		label: {
			french: strings["french"].whatWeDo,
			english: strings["english"].whatWeDo,
		},
		link: "#what-we-do",
		icon: require("../assets/images/what-do.svg"),
	},
];

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { menuShown: false, tooltip: null };
		this.wrapperRef = undefined;
	}
	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}
	hideMenu = () => {
		this.setState({ menuShown: false });
	};
	handleClickOutside = (event) => {
		if (event.target.id !== "bars-menu") {
			if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
				this.hideMenu();
			}
		}
	};
	home = () => {
		history.push("#");
		this.hideMenu();
		// window.location.reload();
	};
	toggleMenu = () => {
		if (this.state.menuShown) {
			this.setState({ menuShown: false });
		} else {
			this.setState({ menuShown: true });
		}
	};
	setWrapperRef = (node) => {
		this.wrapperRef = node;
	};
	renderDrawer = () => {
		return (
			<Navbar.Collapse>
				<Nav className="mr-auto text-light p-3">
					<Nav.Link
						href="#"
						className="text-light"
						onClick={this.home}
					>
						Home
					</Nav.Link>
					<Nav.Link
						href="#contactus"
						className="text-light"
						onClick={this.hideMenu}
					>
						Contact Us
					</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		);
	};
	renderNav = () => {
		return (
			<nav className="navbar navbar-light d-flex flex-row">
				<a className="navbar-brand" href="#" onClick={this.home}>
					<img
						src={require("../assets/images/logo.svg")}
						className="d-inline-block logo"
						alt="logo"
						role="button"
					/>
				</a>
				<span
					className="float-right text-light h4 pt-2 nav-drawer menu"
					onClick={this.toggleMenu}
				>
					{this.renderMenu()}
				</span>
			</nav>
		);
	};
	renderMenu = () => {
		const { menuShown, tooltip } = this.state;
		const { language } = this.props;
		return (
			<div className="menu d-flex flex-row align-items-center">
				{MENU.map((item) => {
					const tooltipLabel = item.label[
						language || "french"
					].toUpperCase();
					return (
						<a
							key={item.link}
							className="pointer tip"
							href={item.link}
						>
							<img src={item.icon} className="icon-header" />
							<b label={tooltipLabel}></b>
						</a>
					);
				})}
			</div>
		);
	};
	render() {
		return (
			<div className="py-3">
				<div className="col-11 mx-auto position-relative">
					{this.renderNav()}
				</div>
			</div>
		);
	}
}

export default withRouter(Header);
