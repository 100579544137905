import React from "react";

import Header from "./components/header.js";
import Cards from "./components/cards.js";
import Footer from "./components/footer.js";
import ContactUs from "./components/contactus.js";
import About from "./components/about.js";
import Mission from "./components/mission.js";
import WhatWeDo from "./components/what-we-do.js";
import ContactUsBubble from "./components/contactusBubble.js";
import Portfolio from './components/portfolio.js';
import Affaires from './components/affaires.js';

import Steps from "./components/steps.mobile.js";
import CardsMobile from "./components/cards.mobile.js";
import ContactUsMobile from "./components/contactus.mobile.js";

import { HashRouter, Switch, Route, useHistory, Re, HashRouterdirect } from "react-router-dom";
import windowSize from "react-window-size";

import "./index.scss";
import "./App.css";
import Terms from './components/terms';
import Policy from './components/policy';

const hideLoader = () => {
	const loader = document.getElementById("splash-screen");
	loader.classList.add("hide-splash");
	const rootApp = document.getElementById("root");
	rootApp.classList.remove("hide-app");
	rootApp.classList.add("show-app");
};

class App extends React.Component {
	componentDidMount() {
		hideLoader();
	}
	render() {
		return (
			<React.Fragment>
				<HashRouter>
					<Switch>
						{this.props.windowWidth <= 768 ? (
							<React.Fragment>
								<Route path="/" exact component={Steps} />
								<Route
									path="/cards"
									exact
									component={CardsMobile}
								/>
								<Route
									path="/contactus"
									exact
									component={ContactUsMobile}
								/>
								<Route
									path="/wifi/terms-and-conditions"
									exact
									component={Terms}
								/>
								<Route
									path="/wifi/privacy-policy"
									exact
									component={Policy}
								/>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Header />
								{/* <Redirect from='/#/' to='/' />
								<Redirect from='/#/contactus' to='/contactus' /> */}
								<Route path="/" exact component={Cards} />
								<Route
									path="/contactus"
									exact
									component={ContactUs}
								/>
								<Route path="/about" exact  component={About} />
								<Route
									path="/mission"
									exact
									component={Mission}
								/>
								<Route
									path="/wifi/privacy-policy"
									
									component={Policy}
								/>
								<Route
									path="/wifi/terms-and-conditions"
									
									component={Terms}
								/>
								<Route
									path="/what-we-do"
									
									component={WhatWeDo}
								/>
								<Route
									path="/portfolio"
									
									component={Portfolio}
								/>
								<Route
									path="/affaires"
									
									component={Affaires}
								/>
								<Footer />
							</React.Fragment>
						)}
					</Switch>
				</HashRouter>
				{this.props.windowWidth > 768 && <ContactUsBubble />}
			</React.Fragment>
		);
	}
}

export default windowSize(App);
