import React, { Component } from "react";
import { CloudTop, Rocket, Smoke, SpaceMan } from "./partials/space.js";

import RocketSVG from "../assets/images/rocket-2.svg";
import PlanetSVG from '../assets/images/planet.svg';
import CloudSVG from '../assets/images/cloud.svg';
import Cloud2SVG from '../assets/images/cloud-2.svg';
import PlanetFlagSVG from '../assets/images/planet-2.svg';
import CloudBottomSVG from '../assets/images/cloud-bottom.svg';

import strings from '../resources/strings.js';

export default class Mission extends Component {
	componentDidMount() {}
	render() {
		const {language}=this.props;
		return (
			<div className="mission">
				<img src={PlanetSVG} className="planet" />
				<img src={RocketSVG} className="rocket" />
				<img src={CloudSVG} className="cloud" />
				<img src={Cloud2SVG} className="cloud-2" />
				<div className="empty-block-half"></div>
				<div className="container mt-5 col-11">
					<h1 className="text-light col-6 title">{strings[language||'french'].mission}</h1>
					<p className="text-light col-5 text">
						{strings[language||'french'].weCombine}
					</p>
				</div>
				<div className="empty-block"></div>
				<img src={PlanetFlagSVG} className="planet-flag" />
				<div className="container mt-5 col-11">
					<div className="col-6 float-right">
						<h1 className="text-light col-6 title">{strings[language||'french'].vision}</h1>
						<p className="text-light col-9 text">
							{strings[language||'french'].delivering}
						</p>
					</div>
				</div>
				<img src={CloudBottomSVG} className="cloud-bottom" />
				<div className="empty-block"></div>
				<div className="empty-block-half"></div>
			</div>
		);
	}
}
