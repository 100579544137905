import React, { Component } from "react";
import { CloudTop, Rocket, Smoke, SpaceMan } from "./partials/space.js";

import RocketSVG from "../assets/images/rocket-2.svg";
import PlanetSVG from '../assets/images/planet.svg';
import CloudSVG from '../assets/images/cloud.svg';
import Cloud2SVG from '../assets/images/cloud-2.svg';
import PlanetFlagSVG from '../assets/images/planet-2.svg';
import CloudBottomSVG from '../assets/images/cloud-bottom.svg';

import strings from '../resources/strings.js';

export default class Terms extends Component {
	componentDidMount() {}
	render() {
		const {language}=this.props;
		return (
			<div className="mission">
				<img src={PlanetSVG} className="planet" />
				<img src={RocketSVG} className="rocket" />
				{/* <img src={CloudSVG} className="cloud" />
				<img src={Cloud2SVG} className="cloud-2" /> */}
				{/* <div className="empty-block-half"></div> */}
                <div style={{display: 'flex', justifyContent: 'center'}} >
                    <div style={{maxWidth: '60vw', color: 'white'}} className='terms' >
                    <h2>Terms and Conditions for AstroFi Social WiFi by AstroFi Tech SASU – Siren 881 966 436</h2>

                    <h4>Last updated: 12/08/2023</h4>

<h4>These Terms and Conditions ("Terms," "Agreement") govern your use of the AstroFi Social WiFi solution provided by AstroFi Tech SASU ("we," "us," or "our"). By accessing or using the AstroFi Social WiFi solution, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the service.</h4>
<br/>
<h4>1. Use of AstroFi Social WiFi</h4>

<h5>1.1 You must comply with all applicable laws and regulations while using the AstroFi Social WiFi solution.</h5>

<h5>1.2 You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</h5>

<h5>1.3 You must not use the AstroFi Social WiFi solution for any unlawful, unauthorized, or improper purpose.</h5>
<br/>
<h4>2. User Content</h4>

<h5>2.1 You may have the opportunity to submit content while using the AstroFi Social WiFi solution, such as reviews or comments. By submitting content, you grant us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content.
</h5>
<h5>2.2 You are solely responsible for the content you submit, and it must not violate any third-party rights or applicable laws.</h5>
<br/>
<h4>3. Intellectual Property</h4>

<h5>3.1 The AstroFi Social WiFi solution, including all software, content, and materials, is protected by copyright, trademark, and other intellectual property laws. You may not modify, reproduce, distribute, create derivative works, reverse engineer, or attempt to gain unauthorized access to the solution.</h5>
<br/>
<h4>4. Privacy</h4>

<h5>4.1 Your use of the AstroFi Social WiFi solution is also governed by our Privacy Policy, which can be found at [insert link to Privacy Policy]. By using the solution, you consent to our collection, use, and sharing of your personal information as described in the Privacy Policy.</h5>
<br/>
<h4>5. Disclaimers</h4>

<h5>5.1 The AstroFi Social WiFi solution is provided "as is" and "as available," without any warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</h5>
<br/>
<h4>6. Limitation of Liability</h4>

<h5>6.1 To the maximum extent permitted by applicable law, AstroFi Tech SASU and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.</h5>
<br/>
<h4>7. Changes to Terms and Conditions</h4>

<h5>7.1 We may update these Terms from time to time for operational, legal, or regulatory reasons. We will notify you of any material changes through the contact information you provide or by posting a prominent notice on our website.</h5>
<br/>
<h4>8. Governing Law and Jurisdiction</h4>

<h5>8.1 These Terms and your use of the AstroFi Social WiFi solution are governed by the laws of [Jurisdiction]. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].</h5>
<br/>
<h4>9. Contact Us</h4>

<h5>9.1 If you have any questions, concerns, or inquiries regarding these Terms and Conditions, please contact us at:</h5> <br/> <br/>

<h4>AstroFi Tech SASU</h4>
<h4>101 Rue de Sèvres, 75006 Paris, France</h4>
<h4><a style={{color: 'white'}} href="mailto:privacy@astrofi.com">privacy@astrofi.com</a></h4>
<h4><a style={{color: 'white'}} href="tel:++33632457265">+33632457265</a></h4>

<h5>By using AstroFi Social WiFi, you acknowledge that you have read, understood, and agree to the terms of these Terms and Conditions.</h5>



                    </div>
                </div>
				<img src={PlanetFlagSVG} className="planet-flag" />
			
				<img src={CloudBottomSVG} className="cloud-bottom" />
				<div className="empty-block-half"></div>
			</div>
		);
	}
}
