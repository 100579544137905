import React, { Component } from "react";
import SwipeJS from "swipejs";

class Steps extends Component {
	componentDidMount() {
		window.mySwipe = new SwipeJS(document.getElementById("slider"));
	}
	firstUse = () => {
		//localStorage.setItem("firstUse", JSON.stringify({ firstUse: false }));
	};
	render() {
		return (
			<div id="slider" className="swipe">
				<div className="swipe-wrap">
					<div
						className="steps"
						style={{ height: window.innerHeight }}
					>
						<div className="s1">
							<img
								src={require("../assets/images/splash1.svg")}
							/>
						</div>
						<div className="s2">
							<img
								src={require("../assets/images/splash2.svg")}
							/>
						</div>
						<div className="s3">
							<img
								src={require("../assets/images/splash3.svg")}
							/>
						</div>
						<img
							className="cover"
							src={require("../assets/images/spaceman-mobile.svg")}
						/>
						<img
							className="cloud-up"
							src={require("../assets/images/cloud-up-mobile.svg")}
						/>
						<img
							className="cloud-left"
							src={require("../assets/images/cloud-left-mobile.svg")}
						/>
						<img
							className="cloud-right"
							src={require("../assets/images/cloud-right-mobile.svg")}
						/>
						<div className="w-75 text-center fixed-bottom mx-auto mb-5">
							<h3 className="text-light my-2">Choisissez</h3>
							<p className="text-light my-3">
								Commencez par choisir l’un des services que nous offrons! 
							</p>
							<span className="dots d-flex flex-row w-25 align-items-center justify-content-center mx-auto my-2">
								<span className="dot-filled mx-1" />
								<span className="dot-empty mx-1" />
							</span>
							<a
								href="/#/cards"
								className="text-light mini-text underline my-3"
								onClick={this.firstUse}
							>
								<u>Skip</u>
							</a>
						</div>
					</div>

					<div
						className="steps"
						style={{ height: window.innerHeight }}
					>
						<div className="s1">
							<img
								src={require("../assets/images/splash1.svg")}
							/>
						</div>
						<div className="s2">
							<img
								src={require("../assets/images/splash2.svg")}
							/>
						</div>
						<div className="s3">
							<img
								src={require("../assets/images/splash3.svg")}
							/>
						</div>
						<img
							className="cover"
							src={require("../assets/images/spaceman2-mobile.svg")}
						/>
						<img
							className="cloud-up"
							src={require("../assets/images/cloud-up-mobile.svg")}
						/>
						<img
							className="cloud-left"
							src={require("../assets/images/cloud-left-mobile.svg")}
						/>
						<img
							className="cloud-right"
							src={require("../assets/images/cloud-right-mobile.svg")}
						/>
						<div className="w-75 text-center fixed-bottom mx-auto mb-5">
							<h3 className="text-light my-2">Contactez-nous</h3>
							<p className="text-light my-3">
								Puis déposez vos coordonnées ou contactez-nous immédiatement!
							</p>
							<span className="dots d-flex flex-row w-25 align-items-center justify-content-center mx-auto my-2">
								<span className="dot-empty mx-1" />
								<span className="dot-filled mx-1" />
							</span>
							<a
								className="w-75 btn btn-md gradient-button btn-rounded text-light mt-3"
								href="/#/contactus"
								onClick={this.firstUse}
							>
								COMMENCER
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Steps;
