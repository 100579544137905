import React from "react";

import VRIcon from "../assets/images/VRIcon.js";
import DigitalMarketingIcon from "../assets/images/DigitalMarketingIcon.js";
import ProductionIcon from "../assets/images/ProductionIcon.js";
import SEOIcon from "../assets/images/SEOIcon.js";
import WebDevIcon from "../assets/images/WebDevIcon.js";
import EmailIcon from "../assets/images/EmailIcon.js";
import UIIcon from "../assets/images/UIIcon.js";
import AppDevIcon from "../assets/images/AppDevIcon.js";
import SMSIcon from "../assets/images/SMSIcon.js";
import RouterIcon from "../assets/images/routerIcon.js";

import {
	DigitalMarketing,
	Production,
	WebDevelpment,
	Seo,
	HostingEmail,
	VR,
	Design,
	AppDev,
	SMS,
	Router,
} from "./services.mobile.js";

import {
	DIGITAL_MARKETING,
	PRODUCTION,
	SEO_ASO,
	WEB_DEVELOPMENT,
	HOSTING_EMAIL,
	SMS_EMAIL,
	UI_UX_DESIGN,
	APP_DEV,
	VR_AR,
	WIFI_SOLUTIONS,
} from "./services.mobile.js";

export default [
	{
		icon: (
			<div className="WIFIIcon" id="wifi-lottie">
				{" "}
			</div>
		),
		title: WIFI_SOLUTIONS,
		size: "md",
		detailsComponent: <Router />,
		animation: "WIFIIcon",
		lottie: "/assets/lottie/Wifi-Animation/data.json",
	},
	{
		icon: (
			<div className="DigitalMarket" id="digital-market-lottie">
				{" "}
			</div>
		),
		title: DIGITAL_MARKETING,
		size: "lg",
		detailsComponent: <DigitalMarketing />,
		animation: "DigitalMarket",
		lottie: "/assets/lottie/DigitalMarketingIcon-Animation/data.json",
	},
	{
		icon: (
			<div className="Production" id="production-lottie">
				{" "}
			</div>
		),
		title: PRODUCTION,
		size: "md",
		detailsComponent: <Production />,
		animation: "Production",
		lottie: "/assets/lottie/ProductionIcon-Animation/data.json",
	},
	{
		icon: (
			<div className="WebDev" id="webdev-lottie">
				{" "}
			</div>
		),
		title: WEB_DEVELOPMENT,
		size: "md",
		detailsComponent: <WebDevelpment />,
		animation: "WebDev",
		lottie: "/assets/lottie/Web-Animation/data.json",
	},
	{
		icon: (
			<div className="SEO" id="seo-lottie">
				{" "}
			</div>
		),
		title: SEO_ASO,
		size: "xl",
		detailsComponent: <Seo />,
		animation: "SEO",
		lottie: "/assets/lottie/SEO-Animation/data.json",
	},
	{
		icon: (
			<div className="EmailIcon" id="email-lottie">
				{" "}
			</div>
		),
		title: HOSTING_EMAIL,
		size: "sm",
		detailsComponent: <HostingEmail />,
		animation: "EmailIcon",
		lottie: "/assets/lottie/EmailServiceIcon-Animation/data.json",
	},
	{
		icon: (
			<div className="VRIcon" id="vricon-lottie">
				{" "}
			</div>
		),
		title: VR_AR,
		size: "sm",
		detailsComponent: <VR />,
		animation: "VRIcon",
		lottie: "/assets/lottie/VR-Animation/data.json",
	},
	{
		icon: (
			<div className="UIIcon" id="ui-lottie">
				{" "}
			</div>
		),
		title: UI_UX_DESIGN,
		size: "lg",
		detailsComponent: <Design />,
		animation: "UIIcon",
		lottie: "/assets/lottie/UI-Animation/data.json",
	},
	{
		icon: (
			<div className="AppDevIcon" id="appdev-lottie">
				{" "}
			</div>
		),
		title: APP_DEV,
		size: "lg",
		detailsComponent: <AppDev />,
		animation: "AppDevIcon",
		lottie: "/assets/lottie/AppDevIcon-Animation/data.json",
	},
	{
		icon: (
			<div className="SMSIcon" id="sms-lottie">
				{" "}
			</div>
		),
		title: SMS_EMAIL,
		size: "md",
		detailsComponent: <SMS />,
		animation: "SMSIcon",
		lottie: "/assets/lottie/SMS-Animation/data.json",
	},
];
